import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import api from "../../api/api";
import {
    Alert,
    Box,
    IconButton,
    InputBase,
    Snackbar,
    useTheme,
} from "@mui/material";
import Header from "../../components/Header";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const ViewUsers = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalRows, setTotalRows] = useState(0);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [message, setMessage] = useState({ type: "", message: "" });
    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };
    const [keyword, setKeyword] = useState('')


    const columns = [

        {
            field: "_id",
            headerName: "Id",
            flex: 1,
            cellClassName: "name-column--cell",
            minWidth: 120,
            renderCell: (params) => <Box
                onClick={
                    () => {
                        window.open(`${window.location.origin}/user/${params.row._id}`, '_blank')
                    }
                }
                sx={{
                    cursor: 'pointer',
                    alignItems: 'center',
                    display: 'flex',
                    justifyContent: 'space-around'
                }}>
                <VisibilityOutlinedIcon />
                <Box ml={2}>{params.row._id.toUpperCase().slice(-6)}</Box>
            </Box>
        },
        {
            field: "businessName",
            headerName: "Studio Name",
            flex: 1,
            cellClassName: "name-column--cell",
            minWidth: 120,
        },
        {
            field: "ownerName",
            headerName: "Owner Name",
            flex: 1,
            cellClassName: "name-column--cell",
            minWidth: 120,
        },
        {
            field: "phone",
            headerName: "Phone",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "email",
            headerName: "Email",
            flex: 1,
            minWidth: 100,
        },
        {
            field: "createdAt",
            headerName: "Created At",
            flex: 1,

            renderCell: (params) => new Date(params.row.createdAt).toLocaleString(),
            minWidth: 200,
        },
    ];

    useEffect(() => {
        (async () => {
            setLoading(true);
            await api
                .get(`/users?page=${currentPage + 1}&keyword=${keyword}`)
                .then((res) => {
                    setUsers(res.data.users);
                    setTotalRows(res.data.totalResults);
                })
                .catch((err) => {
                    setMessage({
                        type: "error",
                        message: "Error loading page",
                    });
                    setSnackbarOpen(true);
                })
                .finally(() => {
                    setLoading(false);
                });
        })();
    }, [currentPage, keyword]);

    return (
        <Box m="20px">
            <Box sx={{
                display: 'flex',
                justifyContent: "space-between",
                alignItems: 'center',
                flexWrap: 'wrap',
                rowGap: 2
            }}>
                <Header title="Users" subtitle="List of registered users" />
                <Box
                    display="flex"
                    backgroundColor={colors.primary[400]}
                    borderRadius="3px"
                >
                    <InputBase
                        onChange={(e) => setKeyword(e.target.value)}
                        sx={{ ml: 2, flex: 1, my: 1, width: '150px' }}
                        placeholder="Search User By Keywords"
                    />
                    <IconButton type="button" sx={{ p: 1 }}>
                        <SearchIcon />
                    </IconButton>
                </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={7000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
                    {message?.message}
                </Alert>
            </Snackbar>

            <Table
                columns={columns}
                getRowId={(row) => row?._id}
                rows={users}
                setCurrentPage={setCurrentPage}
                totalRows={totalRows}
                loading={loading}
            />
        </Box>
    );
};

export default ViewUsers;
