import React, { useState } from "react";
import { IconButton } from "@mui/material";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Image from "../../../components/Image";

const Carousel = ({ images, selectedIndex = 0 }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(selectedIndex);

  const showNextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const showPrevImage = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  return (
    <div style={{ position: "relative" }}>
      <IconButton
        style={{
          position: "absolute",
          left: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "#ddd",
        }}
        aria-label="previous"
        onClick={showPrevImage}
      >
        <NavigateBeforeIcon />
      </IconButton>
      <Image
        src={images[currentImageIndex]}
        alt={`${currentImageIndex + 1}`}
        style={{
          width: "100%",
          height: "auto",
        }}
      />
      <IconButton
        style={{
          position: "absolute",
          right: "10px",
          top: "50%",
          transform: "translateY(-50%)",
          backgroundColor: "#ddd",
        }}
        aria-label="next"
        onClick={showNextImage}
      >
        <NavigateNextIcon />
      </IconButton>
    </div>
  );
};

export default Carousel;
