import React, { useState, lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { AuthStore } from "./context/authContext";
import Loading from "./components/Loading";
import Layout from "./components/Layout";
import ViewProject from "./screens/photoSelector/ViewProject";
import PageNotFound from "./screens/pagenotfound/PageNotFound";
import CreditDetails from "./screens/credits/CreditDetails";
import FAQ from "./screens/others/FAQ";
import Profile from "./screens/others/Profile";
import StudioProfile from "./screens/studio/StudioProfile";
import Inquiry from "./screens/others/Inquiry";
import History from "./screens/credits/History";
import ViewUsers from "./screens/admin/ViewUsers";
import AdminUserViewLayout from "./components/AdminUserViewLayout";
import UserDetail from "./screens/admin/UserDetail";
import UserProjectList from "./screens/admin/UserProjectList";

const Dashboard = lazy(() => import("./screens/dashboard"));
const PhotoSelector = lazy(() =>
  import("./screens/photoSelector/PhotoSelector")
);

Amplify.configure(awsExports);

function App() {
  const [theme, colorMode] = useMode();
  const { state } = AuthStore();
  const [searchTerm, setSearchTerm] = useState("");

  if (state?.loading) {
    return <Loading />;
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Suspense fallback={<Loading />}>
          <Routes>
            <Route
              path="/"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <Dashboard />
                </Layout>
              }
            />
            <Route
              path="/project/:projectId"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <ViewProject />
                </Layout>
              }
            />
            <Route
              path="/credits"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <CreditDetails />
                </Layout>
              }
            />
            <Route
              path="/credits/history"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <History userId={state?.profile?._id} />
                </Layout>
              }
            />
            <Route
              path="/users"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <ViewUsers />
                </Layout>
              }
            />
            <Route
              path="/profile"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <Profile />
                </Layout>
              }
            />
            <Route
              path="/faq"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <FAQ />
                </Layout>
              }
            />
            <Route
              path="/inquiry"
              element={
                <Layout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <Inquiry />
                </Layout>
              }
            />
            <Route
              path="/user/:userId"
              element={
                <AdminUserViewLayout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                  <UserDetail />
                </AdminUserViewLayout>
              }
            />
            <Route
              path="/user/:userId/credits/history"
              element={<AdminUserViewLayout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                <History />
              </AdminUserViewLayout>}
            />
            <Route
              path="/user/:userId/projects"
              element={<AdminUserViewLayout searchTerm={searchTerm} setSearchTerm={setSearchTerm}>
                <UserProjectList />
              </AdminUserViewLayout>}
            />

            <Route
              path="/selector/:selectionToken"
              element={<PhotoSelector />}
            />
            <Route path="/studio/:studioId" element={<StudioProfile />} />
            <Route path="/*" element={<PageNotFound />} />
          </Routes>
        </Suspense>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
