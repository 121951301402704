import { Box, Typography, Grid } from "@mui/material";

const DashboardCard = ({ title, value, backgroundColor, icon }) => {
  return (
    <Grid
      item
      xs={12}
      sm={12}
      md={5}
      lg={3}
      backgroundColor={backgroundColor}
      p="30px"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        height: "130px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Typography variant="h5" fontWeight="600">
          {title}
        </Typography>
        {icon}
      </Box>
      <Typography
        sx={{
          verticalAlign: "center",
        }}
        variant="h4"
        fontWeight="600"
      >
        {value}
      </Typography>
    </Grid>
  );
};

export default DashboardCard;
