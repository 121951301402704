import { Box } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { useState } from "react";

const Table = ({
  rows,
  columns,
  getRowId,
  height,
  rowHeight,
  setCurrentPage,
  totalRows,
  loading = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 20,
    page: 0,
  });

  const onFilterChange = (filter) => { };

  const handlePageChange = (pageModel) => {
    setPaginationModel(pageModel);
    setCurrentPage(pageModel.page);
  };

  return (
    <Box>
      <Box
        m="40px 0 0 0"
        height={height || "75vh"}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          getRowHeight={() => rowHeight || 100}
          getRowId={getRowId}
          loading={loading}
          rowCount={totalRows}
          paginationMode={totalRows ? "server" : "client"}
          pageSizeOptions={[20]}
          paginationModel={paginationModel}
          onPaginationModelChange={handlePageChange}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          disableRowSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default Table;
