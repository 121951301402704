import React, { useState } from "react";

const Image = ({ src, alt, style }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const loadedImageStyle = imageLoaded
    ? {
        display: "block",
        opacity: 1,
      }
    : {};

  const handleImageLoad = () => {
    setImageLoaded(true);
  };
  return (
    <img
      src={src}
      alt={alt}
      onLoad={handleImageLoad}
      style={{
        backgroundColor: "#ddd",
        userSelect: "none",
        ...style,
        ...loadedImageStyle,
      }}
      onContextMenu={(e) => e.preventDefault()}
    />
  );
};

export default Image;
