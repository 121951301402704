import { Alert, Box, Snackbar, Tab, Tabs, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/api";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import styled from "@emotion/styled";
import Loading from "../../components/Loading";
import UploadImages from "./UploadImages";
import Selection from "./Selection";
import ProjectDetail from "./ProjectDetail";
import SelectedImages from "./SelectedImages";

const ViewProject = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { projectId } = useParams();
  const [projectDetails, setProjectDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const [value, setValue] = React.useState("view");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const isNavValid = (newValue) => {
    if (
      newValue === "selectedImages" &&
      projectDetails.status !== "Completed"
    ) {
      setMessage({
        type: "error",
        message: "Photo Selection Not Completed!!",
      });
      setSnackbarOpen(true);
      return false;
    }
    if (newValue === "shareForSelection" && !projectDetails.images.length) {
      setMessage({
        type: "error",
        message: "No Photos Has Been Uploaded!!",
      });
      setSnackbarOpen(true);
      return false;
    }
    if (newValue === "uploadPhotos" && projectDetails.status === "Completed") {
      setMessage({
        type: "error",
        message: "Change Status To Upload More Photos!!",
      });
      setSnackbarOpen(true);
      return false;
    }
    return true;
  };

  const handleChange = (event, newValue) => {
    if (!isNavValid(newValue)) return;
    setValue(newValue);
  };
  useEffect(() => {
    const loadProjectDetails = async () => {
      setLoading(true);
      try {
        const res = await api.get(`/project/${projectId}`);
        setProjectDetails(res.data.project);
      } catch (error) {
        setMessage({
          type: "error",
          message: "Error loading page",
        });
        setSnackbarOpen(true);

        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    loadProjectDetails();
  }, [projectId]);

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
      variant="scrollable"
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      width: "100%",
      backgroundColor: "#635ee7",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(18),
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: "#fff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  if (loading) return <Loading />;

  return (
    <Box m="20px">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>

      {/* HEADER */}
      <Box
        mb={"30px"}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header
          headerStyle={{
            textTransform: "capitalize",
          }}
          title={projectDetails?.projectName}
          subtitle=""
        />
        <Header
          headerStyle={{
            fontSize: "20px",
          }}
          title="Project Status"
          subtitle={projectDetails?.status}
        />
      </Box>
      <Box
        p={"20px"}
        sx={{
          backgroundColor: colors.primary[400],
        }}
      >
        <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
          <StyledTab value={"view"} label="Project Details" />
          <StyledTab value={"uploadPhotos"} label="Upload Photos" />
          <StyledTab value={"shareForSelection"} label="Share For Selections" />
          <StyledTab value={"selectedImages"} label="Selected Images" />
        </StyledTabs>

        {value === "view" && <ProjectDetail projectDetails={projectDetails} />}
        {value === "uploadPhotos" && (
          <UploadImages
            projectId={projectId}
            setProjectDetails={setProjectDetails}
          />
        )}
        {value === "shareForSelection" && (
          <Selection projectDetails={projectDetails} />
        )}
        {value === "selectedImages" && (
          <SelectedImages projectDetails={projectDetails} />
        )}
      </Box>
    </Box>
  );
};

export default ViewProject;
