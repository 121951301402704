import { useContext } from "react";
import createDataContext from "./createDataContext";
import { Auth } from "aws-amplify";
// import api from "../api/api";

const authReducer = (state, { type, payload }) => {
  switch (type) {
    case "SET_USER":
      return {
        ...state,
        user: payload,
      };
    case "SET_SESSION":
      return {
        ...state,
        session: payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: payload,
      };
    case "SET_PROFILE":
      return { ...state, profile: payload };
    default:
      return state;
  }
};

const signOut = (dispatch) => async () => {
  dispatch({
    type: "SET_LOADING",
    payload: true,
  });
  await Auth.signOut()
    .then(() => {
      dispatch({
        type: "SET_USER",
        payload: null,
      });
      dispatch({
        type: "SET_PROFILE",
        payload: null,
      });
    })
    .catch(() => {})
    .finally(() => {
      dispatch({
        type: "SET_LOADING",
        payload: false,
      });
    });
};

const { Context, Provider } = createDataContext(
  authReducer,
  {
    signOut,
  },
  {
    user: null,
    session: null,
    loading: false,
    profile: null,
  }
);

const AuthStore = () => {
  return useContext(Context);
};

export { Provider, AuthStore };
