import { Box, Button, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import QRCode from "react-qr-code";
import html2canvas from "html2canvas";
import { AuthStore } from "../../context/authContext";

const Selection = ({ projectDetails }) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isMessageCopied, setIsMessageCopied] = useState("");
  const { state } = AuthStore();

  const qrCodeRef = useRef(null);
  const link = `${window.location.origin}/selector/${projectDetails?.selectionToken}`;

  const downloadQRCode = () => {
    if (qrCodeRef && qrCodeRef.current) {
      const div = qrCodeRef.current;

      html2canvas(div).then(function (canvas) {
        const imgData = canvas
          .toDataURL("image/png")
          .replace("image/png", "image/octet-stream");
        const downloadLink = document.createElement("a");
        downloadLink.href = imgData;
        downloadLink.download = "qrcode.png";
        downloadLink.click();
      });
    }
  };

  const copyFallback = (text) => {
    const textArea = document.createElement("textarea");
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand("copy");
    document.body.removeChild(textArea);
    setIsCopied(true);
  };
  return (
    <Box
      sx={{
        minHeight: "70vh",
      }}
    >
      <Box mt={3}>
        <Typography
          fontSize={".875rem"}
          color={"rgba(255, 255, 255, 0.8)"}
          mb={0.5}
        >
          Copy link given below or download QR and share it to your customer for
          photo selection. Any one having this link or QR Code and pin (
          {projectDetails?.pin}) can select photos.
        </Typography>
        <Typography
          fontSize={".8rem"}
          color={"rgba(255, 255, 255, 0.8)"}
          mb={2}
        >
          (नीचे दिए गए लिंक को कॉपी करें या क्यूआर डाउनलोड करें और इसे अपने
          ग्राहक के साथ साझा करें। )
        </Typography>
        <Box>
          <Typography
            fontSize={".875rem"}
            color={"rgba(255, 255, 255, 0.8)"}
            sx={{
              wordWrap: "break-word",
            }}
          >
            {link}
          </Typography>
          <Button
            sx={{
              marginTop: 2,
            }}
            disabled={isCopied}
            onClick={() => {
              if (navigator.clipboard) {
                navigator.clipboard
                  .writeText(link)
                  .then(() => {
                    setIsCopied(true);
                  })
                  .catch(() => {
                    // Fallback method if clipboard access is restricted
                    copyFallback(link);
                  });
              } else {
                // Fallback method for browsers that don't support clipboard API
                copyFallback(link);
              }
            }}
            color="secondary"
            variant="outlined"
          >
            {isCopied ? "Copied!!!" : "Copy Link And Share"}
          </Button>
          <Button
            sx={{
              marginLeft: 3,
              marginRight: 3,
              marginTop: 2,
            }}
            onClick={downloadQRCode}
            color="secondary"
            variant="outlined"
          >
            Download QR
          </Button>
          <Button
            sx={{
              marginTop: 2,
            }}
            disabled={isMessageCopied}
            onClick={() => {
              const message = `*Photo Selection Link:* ${link}\n*PIN:* ${
                projectDetails?.pin
              }\n\nStudio Name: ${state.profile?.businessName}\nEvent Name: ${
                projectDetails?.projectName
              }\nExpiration Date: ${new Date(
                projectDetails?.selectionExpiryDate
              ).toLocaleString()}`;
              if (navigator.clipboard) {
                navigator.clipboard
                  .writeText(message)
                  .then(() => {
                    setIsMessageCopied(true);
                  })
                  .catch(() => {
                    copyFallback(message);
                  });
              } else {
                copyFallback(message);
              }
            }}
            color="secondary"
            variant="outlined"
          >
            {isMessageCopied ? "Copied!!!" : "Copy Message And Share"}
          </Button>
        </Box>

        <Box
          ref={qrCodeRef}
          sx={{
            width: "250px",
            padding: "20px",
            borderRadius: "5px",
            backgroundColor: "#fff",
            margin: "20px 0",
          }}
        >
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
            value={link}
            viewBox={`0 0 256 256`}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Selection;
