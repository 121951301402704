import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import withAuthenticator from "../screens/auth/withAuthenticator";
import { tokens } from "../theme";
import { useParams } from "react-router-dom";
import api from "../api/api";
import Loading from "./Loading";
import PageNotFound from "../screens/pagenotfound/PageNotFound";
import HistoryIcon from "@mui/icons-material/History";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Topbar from "./Topbar";
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';


const AdminUserViewLayout = ({ children, searchTerm, setSearchTerm }) => {
    const { userId } = useParams()
    const [userDetails, setUserDetails] = useState({})
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const [sidebarVisible, setSidebarVisible] = useState(false);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [hasError, setHasError] = useState(false)
    const [loading, setLoading] = useState(false)


    const sideBarMenu = [
        {
            title: "",
            subItems: [
                {
                    title: "Profile",
                    icon: <PersonOutlineIcon />,
                    to: `/user/${userId}`,
                    permissionRequired: [],
                },
            ],
        },
        {
            title: "",
            subItems: [
                {
                    title: "Projects",
                    icon: <WorkOutlineIcon fontSize="small" />,
                    to: `/user/${userId}/projects`,
                    permissionRequired: [],
                },
            ],
        },
        {
            title: "",
            subItems: [
                {
                    title: "Credits",
                    icon: <HistoryIcon fontSize="small" />,
                    to: `/user/${userId}/credits/history`,
                    permissionRequired: [],
                },
            ],
        },
    ]

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                setLoading(true)
                setHasError(false);
                const res = await api.get(`/user/${userId}`);
                setUserDetails(res.data.user)

            } catch (error) {
                setHasError(true)
                console.log(error)
            } finally {
                setLoading(false)
            }
        }
        fetchUserDetails()
    }, [userId]);

    if (loading) return <Loading />

    if (hasError) return <PageNotFound />

    // Clone children and add additional props
    const childrenWithProps = React.Children.map(children, (child) =>
        React.cloneElement(child, {
            userDetails
        })
    );


    return (
        <div className="app">
            {isNonMobile && <Sidebar searchTerm={searchTerm} profileImage={userDetails?.profile} profileName={userDetails?.ownerName} sideBarMenu={sideBarMenu} />}
            {sidebarVisible && (
                <Box
                    sx={{
                        position: "absolute",
                        height: "100vh",
                        backgroundColor: colors.primary[400],
                        zIndex: 10000,
                    }}
                >
                    <Sidebar setSidebarVisible={setSidebarVisible} profileImage={userDetails?.profile} profileName={userDetails?.ownerName} sideBarMenu={sideBarMenu} />
                </Box>
            )}
            <main className="content">
                <Topbar
                    handleSearch={(e) => {
                        setSearchTerm(e.target.value);
                    }}
                    handleClick={() => setSidebarVisible(!sidebarVisible)}
                />
                {childrenWithProps}
            </main>
        </div>
    );
};

export default withAuthenticator(AdminUserViewLayout);
