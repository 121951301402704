import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import api from "../../api/api";
import {
  Alert,
  Box,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import Header from "../../components/Header";

const Inquiry = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = async (id, newAlignment) => {
    try {
      await api.put(`/contact/${id}`, {
        isResolved: newAlignment,
      });
      setContacts(
        contacts.map((c) =>
          c._id === id ? { ...c, isResolved: newAlignment } : c
        )
      );
      setMessage({
        type: "success",
        message: "Status changed successfully!!",
      });
      setSnackbarOpen(true);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Error in changing status",
      });
      setSnackbarOpen(true);
    }
  };

  const columns = [
    {
      field: "isResolved",
      headerName: "Inquiry Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box>
          <ToggleButtonGroup
            color={params.row.isResolved ? "secondary" : "error"}
            value={params.row.isResolved}
            exclusive
            onChange={(_, value) => handleChange(params.row._id, value)}
            aria-label="Platform"
          >
            <ToggleButton value={false}>Not Resolved</ToggleButton>
            <ToggleButton value={true}>Resolved</ToggleButton>
          </ToggleButtonGroup>
        </Box>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 120,
    },
    {
      field: "phone",
      headerName: "Phone",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "subject",
      headerName: "Subject",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "message",
      headerName: "Message",
      flex: 1,
      minWidth: 200,
    },

    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,

      renderCell: (params) => new Date(params.row.createdAt).toLocaleString(),
      minWidth: 200,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get(`/contacts?page=${currentPage + 1}`)
        .then((res) => {
          setContacts(res.data.contacts);
          setTotalRows(res.data.totalResults);
        })
        .catch((err) => {
          setMessage({
            type: "error",
            message: "Error loading page",
          });
          setSnackbarOpen(true);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [currentPage]);

  return (
    <Box m="20px">
      <Header title="Inquiries" subtitle="List of queries received" />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>

      <Table
        columns={columns}
        getRowId={(row) => row?._id}
        rows={contacts}
        setCurrentPage={setCurrentPage}
        totalRows={totalRows}
        loading={loading}
      />
    </Box>
  );
};

export default Inquiry;
