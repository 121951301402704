import React, { useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {
  Alert,
  Box,
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import api from "../../api/api";
import Loading from "../../components/Loading";
import axios from "axios";
import imageCompression from "browser-image-compression";

const UploadImages = ({ projectId, setProjectDetails }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagesUploaded, setImagesUploaded] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const [inputType, setInputType] = useState("folder");
  const [folderName, setFolderName] = useState("");
  const [isErrorWhileUploading, setIsErrorWhileUploading] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleImageSelect = async (event) => {
    const files = Array.from(event.target.files).filter(
      (file) => file.type.startsWith("image/") && !file.name.startsWith("._")
    );
    setSelectedImages([...selectedImages, ...files]);
  };

  const uploadImage = async (uploadUrl, imageData) => {
    try {
      const image = await imageCompression(imageData, {
        maxSizeMB: 0.4,
        maxWidthOrHeight: 1600,
        useWebWorker: true,
      });

      await axios.put(uploadUrl, image, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      return uploadUrl.split("?")[0];
    } catch (error) {
      throw error;
    }
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    setIsErrorWhileUploading(false);
    try {
      if (selectedImages.length) {
        setLoading(true);
        const uploadBatchSize = 4;
        let imagesUploadedCount = imagesUploaded.length;
        const totalUploadedImages = imagesUploaded;

        const uploadUrlRes = await api.post("/project/uploadUrls", {
          keys:
            inputType === "folder"
              ? selectedImages.map(
                  (i) => `${projectId}/${i.webkitRelativePath}`
                )
              : selectedImages.map(
                  (i) => `${projectId}/${folderName}/${i.name}`
                ),
        });

        while (imagesUploadedCount < selectedImages.length) {
          const uploadBatch = selectedImages.slice(
            imagesUploadedCount,
            imagesUploadedCount + uploadBatchSize
          );

          const uploadUrlBatch = uploadUrlRes.data.urls.slice(
            imagesUploadedCount,
            imagesUploadedCount + uploadBatchSize
          );

          const uploadPromises = uploadUrlBatch.map(async (url, i) => {
            const uploadedUrl = await uploadImage(url, uploadBatch[i]);
            return uploadedUrl;
          });

          const uploadedImages = await Promise.all(uploadPromises);

          totalUploadedImages.push(...uploadedImages);

          imagesUploadedCount += uploadBatch.length;
          setImagesUploaded([...imagesUploaded, ...uploadedImages]);
        }

        // Create a Set to store unique values
        const uniqueSet = new Set(
          selectedImages.map((images) =>
            images.webkitRelativePath.split("/").slice(0, -1).join("/")
          )
        );

        // Convert the Set back to an array to get unique values
        const foldersPath = Array.from(uniqueSet);

        const response = await api.put(`/project/${projectId}`, {
          $addToSet: {
            images: totalUploadedImages,
            folders: folderName ? [folderName] : foldersPath,
          },
          status: "Ready For Selection",
        });
        setProjectDetails(response.data.updatedProject);
        setLoading(false);
        setSelectedImages([]);
        setImagesUploaded([]);
        setFolderName("");
        setMessage({
          type: "success",
          message: "Files Uploaded Successfully!!",
        });
        setSnackbarOpen(true);
      } else {
        setMessage({
          type: "error",
          message: `Please select ${inputType}!!`,
        });
        setSnackbarOpen(true);
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        message: "Error Occurred While Uploading Files!!",
      });
      if (imagesUploaded.length) {
        setIsErrorWhileUploading(true);
      }
      setSnackbarOpen(true);
      console.error(error);
    }
  };

  if (isErrorWhileUploading) {
    return (
      <Box
        sx={{
          height: "60vh",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography color={"#ddd"} textTransform={"capitalize"}>
            Error Occurred While Uploading Files.
            <br />
            {imagesUploaded.length} out of {selectedImages.length} has been
            uploaded.
            <br />
            Resume Upload or Refresh the page for Canceling.
          </Typography>
          <Button
            sx={{
              marginTop: "15px",
            }}
            onClick={handleUpload}
            color="secondary"
            variant="contained"
          >
            Resume Uploading
          </Button>
        </Box>
      </Box>
    );
  }

  if (loading) {
    return (
      <Loading
        height="60vh"
        text={`${imagesUploaded.length} out of ${selectedImages.length} Uploaded!!`}
      />
    );
  }

  return (
    <form method="post" onSubmit={handleUpload}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          height: "54vh",
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        <RadioGroup
          row
          aria-labelledby=""
          name="checkBox"
          sx={{
            marginTop: 3,
            "& .MuiRadio-colorSecondary.Mui-checked": {
              color: "#fff",
            },
          }}
          onChange={(e) => {
            setInputType(e.target.value);
          }}
        >
          <FormControlLabel
            value="folder"
            control={<Radio color="secondary" />}
            label="Upload By Folder"
            checked={inputType === "folder"}
          />
          <FormControlLabel
            value="file"
            control={<Radio color="secondary" />}
            label="Upload By File"
            checked={inputType === "file"}
          />
        </RadioGroup>
        <Box sx={{ display: "flex" }} mt={3}>
          {inputType === "file" && (
            <TextField
              variant="outlined"
              type="text"
              label="Folder Name"
              onChange={(e) => setFolderName(e.target.value)}
              value={folderName || ""}
              required
              sx={{
                gridColumn: "span 4",
                marginRight: "20px",
                width: "400px",
                "& label.Mui-focused": {
                  color: "inherit !important",
                },
                "& .MuiOutlinedInput-root": {
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#fff",
                  },
                },
              }}
            />
          )}
          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="image-upload"
              type="file"
              multiple
              onChange={handleImageSelect}
              {...(inputType === "folder" && {
                webkitdirectory: "",
                directory: "",
                mozdirectory: "",
                msdirectory: "",
                odirectory: "",
              })}
            />
            <label htmlFor={"image-upload"}>
              <Button
                variant="contained"
                color="neutral"
                component="span"
                sx={{
                  width: "150px",
                }}
                startIcon={<CloudUploadIcon />}
              >
                Select {inputType}
              </Button>
            </label>
          </Box>
        </Box>
        {selectedImages.length ? (
          <Typography mt={3}>
            {selectedImages.length} images are ready for uploads. Click 'Save
            Images' Button to start uploading.
          </Typography>
        ) : (
          <></>
        )}
      </Box>
      <Box
        display="flex"
        justifyContent="end"
        mt={"20px"}
        sx={{ gridColumn: "span 2" }}
      >
        <Button type="submit" color="secondary" variant="contained">
          Save Images
        </Button>
      </Box>
    </form>
  );
};

export default UploadImages;
