import React from "react";
import styled from "@emotion/styled";
import { Typography, Button } from "@mui/material";

const SuccessContent = styled.div`
  text-align: center;
  background-color: #fff;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`;

const SuccessHeading = styled(Typography)`
  color: #333;
  margin-bottom: 20px;
`;

const SuccessMessage = styled(Typography)`
  color: #666;
  line-height: 1.6;
  margin-bottom: 30px;
`;

const SuccessButton = styled(Button)`
  && {
    padding: 10px 20px;
    text-decoration: none;
    color: #fff;
    background-color: #4caf50;
    border-radius: 4px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #388e3c;
    }
  }
`;

const SuccessPage = ({ buttonText, message, onButtonClicked }) => {
  return (
    <SuccessContent>
      <SuccessHeading variant="h4">
        Thank you for using PhotoSelection.Asia
      </SuccessHeading>
      <SuccessMessage variant="body1">{message}</SuccessMessage>
      <SuccessMessage
        sx={{
          marginTop: "-25px",
        }}
        variant="body1"
      >
        God Bless You!!
      </SuccessMessage>
      <SuccessButton variant="contained" onClick={onButtonClicked}>
        {buttonText}
      </SuccessButton>
    </SuccessContent>
  );
};

export default SuccessPage;
