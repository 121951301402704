import { Box, Grid, MenuItem, TextField } from "@mui/material";
import React, { useState } from "react";
import TitleBar from "../../components/TitleBar";
import ImageContainer from "./components/ImageContainer";

const ProjectDetail = ({ projectDetails }) => {
  const [values, setValues] = useState({ ...projectDetails });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <Box
      sx={{
        minHeight: "70vh",
      }}
    >
      <Grid container spacing={2} my={1} mb={3}>
        <Grid xs={12} sm={12} lg={4} item>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Project Name"
            onChange={handleChange}
            value={values.projectName || ""}
            name="projectName"
            required
            disabled
            sx={{
              gridColumn: "span 2",
              "& label.Mui-focused": {
                color: "inherit !important",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff", // Changes outline color when focused
                },
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} lg={4} item>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Status"
            select
            onChange={handleChange}
            value={values?.status || ""}
            name="status"
            required
            disabled
            sx={{
              gridColumn: "span 1",
              "& label.Mui-focused": {
                color: "inherit !important",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff", // Changes outline color when focused
                },
              },
            }}
          >
            <MenuItem value="Ready For Uploads">Ready For Uploads</MenuItem>
            <MenuItem value="Ready For Selection">Ready For Selection</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </TextField>
        </Grid>
        <Grid xs={12} sm={12} lg={4} item>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Pin"
            onChange={handleChange}
            value={values.pin || ""}
            name="pin"
            required
            disabled
            sx={{
              gridColumn: "span 2",
              "& label.Mui-focused": {
                color: "inherit !important",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff", // Changes outline color when focused
                },
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} lg={4} item>
          <TextField
            fullWidth
            disabled
            multiline
            rows={2}
            variant="outlined"
            label="Description"
            onChange={handleChange}
            value={values.description || ""}
            name="description"
            sx={{
              gridColumn: "span 4",
              "& label.Mui-focused": {
                color: "inherit !important",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff", // Changes outline color when focused
                },
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} lg={4} item>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Created At"
            value={new Date(values.createdAt).toLocaleString() || ""}
            name="createdAt"
            required
            disabled
            sx={{
              gridColumn: "span 2",
              "& label.Mui-focused": {
                color: "inherit !important",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff", // Changes outline color when focused
                },
              },
            }}
          />
        </Grid>
        <Grid xs={12} sm={12} lg={4} item>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Selection Expiry"
            value={new Date(values.selectionExpiryDate).toLocaleString() || ""}
            name="createdAt"
            required
            disabled
            sx={{
              gridColumn: "span 2",
              "& label.Mui-focused": {
                color: "inherit !important",
              },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#fff", // Changes outline color when focused
                },
              },
            }}
          />
        </Grid>
      </Grid>
      <TitleBar>Uploaded Images</TitleBar>

      <ImageContainer
        images={projectDetails.images}
        folders={projectDetails.folders}
        projectId={projectDetails._id}
      />
    </Box>
  );
};

export default ProjectDetail;
