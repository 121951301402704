import React, { useEffect, useState } from "react";
import { styles } from "./styles";

function Login({ setForgotPassword, onSignIn, onRegister }) {
  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (errorMessage !== "") {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const { username, password } = values;
      await onSignIn(username, password);
    } catch (error) {
      switch (error?.code) {
        case "NotAuthorizedException":
          setErrorMessage("Incorrect username or password");
          break;
        case "UserNotFoundException":
          setErrorMessage("User Not Found");
          break;
        default:
          setErrorMessage("Something Went Wrong");
          break;
      }
    }
    setLoading(false);
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(#1845ad, #23a2f6)",
          left: "-80px",
          top: "-80px",
        }}
      ></div>
      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(to right, #ff512f, #f09819)",
          right: "-30px",
          bottom: "-80px",
        }}
      ></div>
      <form method="post" onSubmit={handleSubmit} style={styles.form}>
        <h3 style={styles.header}>Login Here</h3>
        <label htmlFor="username" style={styles.label}>
          Username
        </label>
        <input
          type="text"
          placeholder="Username"
          required
          name="username"
          value={values.username || ""}
          onChange={handleInputChange}
          style={styles.input}
        />
        <label htmlFor="password" style={styles.label}>
          Password
        </label>
        <input
          type="password"
          placeholder="Password"
          required
          name="password"
          value={values.password || ""}
          onChange={handleInputChange}
          style={styles.input}
        />
        {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}

        <button type="submit" disabled={loading} style={styles.button}>
          {loading ? "Logging in..." : "Login"}
        </button>
        <div style={styles.nav}>
          <div onClick={onRegister} style={styles.navButton}>
            Create Account
          </div>
          <div onClick={() => setForgotPassword(true)} style={styles.navButton}>
            Forgot Password?
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
