export const styles = {
  container: {
    position: "absolute",
    transform: "translate(-50%,-50%)",
    left: "50%",
    top: "50%",
    width: "430px",
    height: "520px",
  },
  shape: {
    height: "200px",
    width: "200px",
    position: "absolute",
    borderRadius: "50%",
  },
  form: {
    height: "520px",
    width: "400px",
    backgroundColor: "rgba(255,255,255,0.13)",
    position: "absolute",
    transform: "translate(-50%,-50%)",
    top: "50%",
    left: "50%",
    borderRadius: "10px",
    backdropFilter: "blur(10px)",
    border: "2px solid rgba(255,255,255,0.1)",
    boxShadow: "0 0 40px rgba(8,7,16,0.6)",
    padding: "50px 35px",
  },
  header: {
    fontSize: "32px",
    fontWeight: "500",
    lineHeight: "42px",
    textAlign: "center",
  },
  label: {
    display: "block",
    marginTop: "30px",
    fontSize: "16px",
    fontWeight: "500",
  },
  input: {
    display: "block",
    height: "50px",
    width: "100%",
    backgroundColor: "rgba(255,255,255,0.07)",
    borderRadius: "3px",
    padding: "0 10px",
    marginTop: "8px",
    fontSize: "14px",
    fontWeight: "300",
    borderWidth: 0,
    outline: "none",
    color: "#ffffff",
    caretColor: "#ffffff",
  },
  button: {
    marginTop: "50px",
    width: "100%",
    backgroundColor: "#ffffff",
    color: "#080710",
    padding: "15px 0",
    fontSize: "18px",
    fontWeight: "500",
    borderRadius: "5px",
    cursor: "pointer",
    borderWidth: 0,
  },
  nav: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: "25px",
  },
  navButton: {
    background: "red",
    width: "150px",
    borderRadius: "3px",
    backgroundColor: "rgba(255,255,255,0.27)",
    color: "#eaf0fb",
    textAlign: "center",
    verticalAlign: "center",
    cursor: "pointer",
    margin: "0 5px",
  },
  errorMessage: {
    position: "absolute",
  },
};
