import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import api from "../../api/api";
import { Alert, Box, Snackbar } from "@mui/material";

const CreditHistory = ({ userId }) => {
  const [creditHistory, setCreditHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "type",
      headerName: "Transaction Type",
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 120,
    },
    {
      field: "amount",
      headerName: "Number Of Credits",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "description",
      headerName: "Description",
      flex: 1,
      minWidth: 100,
    },

    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,

      renderCell: (params) => new Date(params.row.createdAt).toLocaleString(),
      minWidth: 200,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get(`/user/${userId}/credit/transactions?page=${currentPage + 1}`)
        .then((res) => {
          setCreditHistory(res.data.creditTransactions);
          setTotalRows(res.data.totalResults);
        })
        .catch((err) => {
          setMessage({
            type: "error",
            message: "Error loading page",
          });
          setSnackbarOpen(true);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [currentPage, userId]);

  return (
    <Box m="20px">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>

      <Table
        columns={columns}
        getRowId={(row) => row?._id}
        rows={creditHistory}
        setCurrentPage={setCurrentPage}
        totalRows={totalRows}
        loading={loading}
      />
    </Box>
  );
};

export default CreditHistory;
