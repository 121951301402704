import React from "react";
import { CircularProgress, Typography, Box, useTheme } from "@mui/material";

const Loading = ({ text = "Loading...", height = "100vh" }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height,
      }}
    >
      <CircularProgress
        size={64}
        color={theme.palette.mode === "dark" ? "secondary" : "primary"}
      />
      <Typography variant="h6" color="textSecondary" sx={{ marginTop: 2 }}>
        {text}
      </Typography>
    </Box>
  );
};

export default Loading;
