import { Box, Tab, Tabs, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../../theme";
import styled from "@emotion/styled";
import CreditHistory from "./CreditHistory";
import TransactionHistory from "./TransactionHistory";
import { useParams } from "react-router-dom";

const History = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [value, setValue] = React.useState("Transaction History");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const params = useParams();
  const userId = params?.userId || props?.userId

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
      variant="scrollable"
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      width: "100%",
      backgroundColor: "#635ee7",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(18),
      marginRight: theme.spacing(1),
      color: "rgba(255, 255, 255, 0.7)",
      "&.Mui-selected": {
        color: "#fff",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  return (
    <Box m="20px">
      <Box
        p={"20px 10px"}
        sx={{
          backgroundColor: colors.primary[400],
        }}
      >
        <Box p={"0 20px"}>
          <StyledTabs value={value} onChange={handleChange} aria-label="tabs">
            <StyledTab
              value={"Transaction History"}
              label="Transaction History"
            />
            <StyledTab value={"Credit History"} label="Credit History" />
          </StyledTabs>
        </Box>

        {value === "Transaction History" && <TransactionHistory userId={userId} />}
        {value === "Credit History" && <CreditHistory userId={userId} />}
      </Box>
    </Box>
  );
};

export default History;
