import React, { useEffect, useState } from "react";
import { styles } from "./styles";

function ChangePassword({ onChangePassword }) {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (errorMessage !== "") {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000); // Clear error message after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleChangePassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      return setErrorMessage("Passwords does not match");
    }
    try {
      setLoading(true);
      await onChangePassword(newPassword);
    } catch (error) {
      switch (error.code) {
        case "NotAuthorizedException":
          setErrorMessage("Password cannot be changed");
          break;
        case "LimitExceededException":
          setErrorMessage("Attempt limit exceeded, please try after some time");
          break;
        case "InvalidPasswordException":
          setErrorMessage("Password should have more that 8 characters");
          break;
        default:
          setErrorMessage("Something went wrong");
          break;
      }
    }
    setLoading(false);
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(#1845ad, #23a2f6)",
          left: "-80px",
          top: "-80px",
        }}
      ></div>
      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(to right, #ff512f, #f09819)",
          right: "-30px",
          bottom: "-80px",
        }}
      ></div>
      <form method="post" onSubmit={handleChangePassword} style={styles.form}>
        <h3 style={styles.header}>Change Password</h3>
        <label htmlFor="username" style={styles.label}>
          New Password
        </label>
        <input
          placeholder="New Password"
          required
          name="newPassword"
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          style={styles.input}
        />
        <label htmlFor="password" style={styles.label}>
          Confirm Password
        </label>
        <input
          type="password"
          placeholder="Confirm Password"
          required
          name="confirmPassword"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          style={styles.input}
        />
        {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}

        <button type="submit" disabled={loading} style={styles.button}>
          {loading ? "Validating..." : "Change Password"}
        </button>
      </form>
    </div>
  );
}

export default ChangePassword;
