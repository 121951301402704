import { Box, Button, Typography } from "@mui/material";
import React from "react";
import TitleBar from "../../components/TitleBar";
import ImageContainer from "./components/ImageContainer";
import SelectedPhotoCard from "./components/SelectedPhotoCard";

const SelectedImages = ({ projectDetails }) => {
  const selectedImages = projectDetails?.selectedImages?.map(
    (image) => image.image
  );

  function generateBatchFile(fileNames) {
    const batchCommands = [];

    batchCommands.push(`if not exist "SELECTED" mkdir "SELECTED"`);

    fileNames.forEach((fileName) => {
      const sourcePath = `"${fileName}"`; // Replace with the path to your images

      const filePath = fileName.split("/"); // Split path into directory and file name
      const directory = filePath.slice(0, -1).join("\\"); // Extract directory
      const targetDirectory = `SELECTED\\${directory}`; // Target directory for each file

      batchCommands.push(
        `if not exist "SELECTED\\${directory}" mkdir "SELECTED\\${directory}"`
      );

      batchCommands.push(
        `if exist ${sourcePath.split("/").join("\\")} ( xcopy /y ${sourcePath
          .split("/")
          .join("\\")} "${targetDirectory}\\"  )`
      );
    });

    const batchContent = batchCommands.join("\n");
    const blob = new Blob([batchContent], { type: "text/plain" });
    return URL.createObjectURL(blob);
  }

  function generateShellScript(fileNames) {
    const shellCommands = [];

    shellCommands.push(`mkdir -p SELECTED`);

    fileNames.forEach((fileName) => {
      const sourcePath = `"${fileName}"`;

      const filePath = fileName.split("/");
      const directory = filePath.slice(0, -1).join("/");
      const targetDirectory = `SELECTED/${directory}`;

      shellCommands.push(`mkdir -p "SELECTED/${directory}"`);
      shellCommands.push(`cp ${sourcePath} "${targetDirectory}/"`);
    });

    const shellContent = shellCommands.join("\n");
    const blob = new Blob([shellContent], { type: "text/plain" });
    return URL.createObjectURL(blob);
  }

  const handleDownload = (fileNames) => {
    const batchURL = generateBatchFile(fileNames);
    const a = document.createElement("a");
    a.href = batchURL;
    a.download = "sort_selected_images.bat";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(batchURL);
  };

  const handleMacDownload = (fileNames) => {
    const batchURL = generateShellScript(fileNames);
    const a = document.createElement("a");
    a.href = batchURL;
    a.download = "sort.sh";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(batchURL);
  };

  return (
    <Box
      sx={{
        minHeight: "70vh",
      }}
    >
      <Typography
        fontSize={".875rem"}
        mt={2}
        color={"rgba(255, 255, 255, 0.8)"}
      >
        Selection for this project is completed. Please folow below steps to
        seperate selection.
      </Typography>
      <Typography fontSize={".8rem"} color={"rgba(255, 255, 255, 0.8)"}>
        (इस प्रोजैक्ट के लिए चयन पूरा हो गया है। चयन को अलग करने के लिए कृपया
        निम्न चरणों का पालन करें| )
      </Typography>

      <Typography fontSize={"1.3rem"} my={2}>
        (1) For Window User : Sorting using Auto Sorting File.
      </Typography>

      <Button
        onClick={() =>
          handleDownload(
            selectedImages.map((s) =>
              decodeURIComponent(s.split(`${projectDetails._id}/`)[1])
            )
          )
        }
        color="secondary"
        variant="outlined"
        sx={{
          marginBottom: "20px",
        }}
      >
        Download Auto Sorting File
      </Button>
      <Typography fontSize={".875rem"} color={"rgba(255, 255, 255, 0.8)"}>
        (1) Download Auto Sorting file and copy it to parent folder of the
        folder that you have uploaded.
      </Typography>
      <Typography fontSize={".875rem"} color={"rgba(255, 255, 255, 0.8)"}>
        (2)Click on batch file then sorting will be start automatically.
      </Typography>

      <Typography fontSize={"1.3rem"} my={2}>
        (2) For Mac User : Sorting using Auto Sorting File.
      </Typography>

      <Button
        onClick={() =>
          handleMacDownload(
            selectedImages.map((s) =>
              decodeURIComponent(s.split(`${projectDetails._id}/`)[1])
            )
          )
        }
        color="secondary"
        variant="outlined"
        sx={{
          marginBottom: "20px",
        }}
      >
        Download Auto Sorting File
      </Button>

      <Typography fontSize={".875rem"} color={"rgba(255, 255, 255, 0.8)"}>
        (1) Download Auto Sorting file and copy it to parent folder of the
        folder that you have uploaded.
      </Typography>
      <Typography
        mb={2}
        fontSize={".875rem"}
        color={"rgba(255, 255, 255, 0.8)"}
      >
        (2)Click on batch file then sorting will be start automatically.
      </Typography>

      {projectDetails.comment && (
        <Box my={3}>
          <TitleBar>User's Comment</TitleBar>
          <Typography
            mt={1}
            fontSize={".875rem"}
            color={"rgba(255, 255, 255, 0.8)"}
          >
            {projectDetails?.comment}
          </Typography>
        </Box>
      )}

      <TitleBar
        endComponent={
          <Typography fontSize={"20px"}>
            {projectDetails.selectedImages.length}/
            {projectDetails.images.length}
          </Typography>
        }
      >
        Selected Images
      </TitleBar>
      <ImageContainer
        images={selectedImages}
        folders={projectDetails.folders}
        projectId={projectDetails?._id}
        view="selectedImages"
        photoComponent={(image) => (
          <SelectedPhotoCard
            image={image}
            selectedImages={selectedImages}
            comment={
              projectDetails?.selectedImages?.[selectedImages.indexOf(image)]
                ?.comment
            }
          />
        )}
      />
    </Box>
  );
};

export default SelectedImages;
