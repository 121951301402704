import { Box, useTheme } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const FAQ = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const faqs = [
    {
      question: "What is a 'Project' in our system?",
      answer:
        "Project is the event or a unique identifier for a customer event, such as 'ABC weds XYZ'.",
    },
    {
      question: "How many types of project statuses exist, and what are they?",
      answer:
        "There are three types of project statuses: (1) Ready For Uploads, (2) Ready For Selection, and (3) Completed.",
    },
    {
      question: "What does the 'Ready For Uploads' status indicate?",
      answer:
        "It indicates that the project is currently in the photo uploading stage. It is the default status when creating a project.",
    },
    {
      question:
        "When should the project status be changed to 'Ready For Selection'?",
      answer:
        "The status should be changed to 'Ready For Selection' when photos are uploaded and selection link is ready to be sent to the customer for selection.",
    },
    {
      question: "What action marks a project as 'Completed'?",
      answer:
        "When the customer sends back their photo selection, the project should be marked as 'Completed'. The status will be displayed as 'Completed', and you can download the selection only for completed projects.",
    },
    {
      question: "What happens if a project reaches its expiry date?",
      answer:
        "After the expiry date, customer cannot be accessed the selection link anymore. It's crucial to finish the selection before its expiry date.",
    },
    {
      question: "What is 'Credit' in our system?",
      answer: "Credit is virtual currency used to create projects.",
    },
    {
      question: "Where can you buy Credit?",
      answer: "Credits can be purchased from the Credit Details page.",
    },
    {
      question: "How many credits are deducted when a project is created?",
      answer: "1 credit is deducted when you create a project.",
    },
    {
      question: "Are there monthly or yearly payment plans?",
      answer:
        "No, there are no monthly or yearly payment plans. Payment is required per project.",
    },
    {
      question: "Are original images uploaded?",
      answer:
        "No, we do not upload original images. Instead, we create small copies of your images that are not printable to ensure image security.",
    },
    {
      question: "How can I use the smart selection tool?",
      answer:
        "Simply download our smart selection tool, open it, select the folder and selection file, then hit the 'Sort' button to create separate folders for selected photos.",
    },
  ];

  return (
    <Box m={"20px"}>
      <Header
        title="FAQs"
        subtitle="Some Frequently Asked Questions and Answers"
      />

      <Box mt={"20px"}>
        {faqs.map((faq, i) => (
          <Accordion
            key={i}
            sx={{
              backgroundColor: colors.primary[400],
              color: "#ddd",
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={i}
              id={i}
            >
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
