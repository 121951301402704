import React, { useEffect, useState } from "react";
import Table from "../../components/Table";
import api from "../../api/api";
import { Alert, Box, Snackbar } from "@mui/material";

const TransactionHistory = ({ userId }) => {
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const columns = [
    {
      field: "txnid",
      headerName: "Transaction Id",
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 180,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => <Box>₹{params.row.amount}</Box>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Box
          sx={{
            textTransform: "capitalize",
          }}
          color={params.row.status === "success" ? "secondary" : "error"}
        >
          {params.row.status}
        </Box>
      ),
    },
    {
      field: "udf2",
      headerName: "Credit Points",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "payment_source",
      headerName: "Payment Source",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => (
        <Box
          sx={{
            textTransform: "capitalize",
          }}
        >
          {params.row.payment_source}
        </Box>
      ),
    },

    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,

      renderCell: (params) => new Date(params.row.createdAt).toLocaleString(),
      minWidth: 200,
    },
  ];

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get(`/user/${userId}/payment/transactions?page=${currentPage + 1}`)
        .then((res) => {
          setTransactionHistory(res.data.transactions);
          setTotalRows(res.data.totalResults);
        })
        .catch((err) => {
          setMessage({
            type: "error",
            message: "Error loading page",
          });
          setSnackbarOpen(true);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [currentPage, userId]);

  return (
    <Box m="20px">
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>

      <Table
        columns={columns}
        getRowId={(row) => row?._id}
        rows={transactionHistory}
        setCurrentPage={setCurrentPage}
        totalRows={totalRows}
        loading={loading}
      />
    </Box>
  );
};

export default TransactionHistory;
