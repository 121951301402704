import { Box } from "@mui/material";
import React from "react";

const VideoEmbed = ({ url }) => {
  return (
    <div style={{ margin: "20px 0" }}>
      <iframe
        width="100%"
        height="315"
        src={url}
        title={`Embedded Video`}
        frameBorder="0"
        allowFullScreen
      ></iframe>
    </div>
  );
};

const Videos = ({ videos }) => {
  return (
    <Box
      sx={{
        margin: "15px 20px",
      }}
    >
      {videos?.map((url, index) => (
        <VideoEmbed key={index} url={url} />
      ))}
    </Box>
  );
};

export default Videos;
