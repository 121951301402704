import {
  Avatar,
  Box,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  //  ColorModeContext,

  tokens,
} from "../theme";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import { AuthStore } from "../context/authContext";
// import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
// import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
// import { useContext } from "react";

const Topbar = ({ handleSearch, handleClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  // const colorMode = useContext(ColorModeContext);
  const { state } = AuthStore();

  const isNonMobile = useMediaQuery("(min-width:600px)");

  return (
    <Box display="flex" justifyContent="space-between" p={2}>
      {/* SEARCH BAR */}
      {isNonMobile ? (
        <Box
          display="flex"
          backgroundColor={colors.primary[400]}
          borderRadius="3px"
        >
          <InputBase
            onChange={handleSearch}
            sx={{ ml: 2, flex: 1 }}
            placeholder="Search"
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchIcon />
          </IconButton>
        </Box>
      ) : (
        <Box>
          <IconButton id="basic-button" onClick={handleClick}>
            <MenuOutlinedIcon />
          </IconButton>
        </Box>
      )}

      {/* ICONS */}
      <Box display="flex">
        {/* <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === "dark" ? (
            <LightModeOutlinedIcon />
          ) : (
            <DarkModeOutlinedIcon />
          )}
        </IconButton> */}
        {!isNonMobile && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography mr={1}>
              Hello, {state?.profile?.ownerName || "User"}!
            </Typography>
            <Avatar
              sx={{ width: 34, height: 34 }}
              alt={state?.profile?.ownerName || "User"}
              src={state?.profile?.profile}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Topbar;
