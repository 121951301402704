import React, { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import ChangePassword from "./ChangePassword";
import Login from "./Login";
import { AuthStore } from "../../context/authContext";
import Loading from "../../components/Loading";
import { useCallback } from "react";
import api from "../../api/api";
import ForgotPassword from "./ForgotPassword";
import RegisterAccount from "./RegisterAccount";
import { Box } from "@mui/material";
import logo from '../../assets/logo.png'

const AuthComponentWrapper = ({ children }) => {
  return <Box>
    <img
      alt="logo"
      src={logo}
      style={{
        height: "8vh",
        objectFit: 'contain',
        margin: '15px',
        position: 'absolute',
        zIndex: '100',
      }}
    />
    {children}
  </Box>
}

const withAuthenticator = (WrappedComponent) => {
  const Authenticator = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [isPasswordChangeRequired, setIsPasswordChangeRequired] =
      useState(false);
    const [forgotPassword, setForgotPassword] = useState(false);
    const { state, dispatch } = AuthStore();
    const [signup, setSignup] = useState(false);

    const checkUserAuthentication = useCallback(async () => {
      try {
        const currentUser = await Auth.currentAuthenticatedUser();
        const result = await api.get("/user/me");
        await dispatch({
          type: "SET_PROFILE",
          payload: result.data.user,
        });
        await dispatch({
          type: "SET_USER",
          payload: currentUser,
        });
        setIsLoading(false);
      } catch (error) {
        console.error("Error checking user authentication:", error);
        setIsLoading(false);
      }
    }, [dispatch]);

    useEffect(() => {
      checkUserAuthentication();
    }, [checkUserAuthentication]);

    const handleSignIn = (username, password) => {
      return new Promise(async (resolve, reject) => {
        try {
          const user = await Auth.signIn(username, password);

          if (user?.challengeName === "NEW_PASSWORD_REQUIRED") {
            dispatch({
              type: "SET_USER",
              payload: user,
            });
            setIsPasswordChangeRequired(true);
          } else {
            const result = await api.get("/user/me");
            await dispatch({
              type: "SET_PROFILE",
              payload: result.data.user,
            });
            dispatch({
              type: "SET_USER",
              payload: user,
            });
          }
          resolve(); // Resolve the promise after successful sign-in
        } catch (error) {
          // console.log(error);
          reject(error); // Reject the promise with the error if sign-in fails
        }
      });
    };

    const handlePasswordChange = (newPassword) => {
      return new Promise(async (resolve, reject) => {
        try {
          await Auth.completeNewPassword(state?.user, newPassword);
          setIsPasswordChangeRequired(false);
          await checkUserAuthentication();
          resolve(); // Resolve the promise if the password change is successful
        } catch (error) {
          console.error("Error completing password change:", error);
          reject(error); // Reject the promise with the error if an error occurs
        }
      });
    };

    if (isLoading) {
      // Render loading UI
      return <Loading />;
    }

    if (isPasswordChangeRequired) {
      // Render password change UI
      return <AuthComponentWrapper>
        <ChangePassword onChangePassword={handlePasswordChange} />
      </AuthComponentWrapper>;
    }

    if (forgotPassword) {
      return <AuthComponentWrapper>
        <ForgotPassword onBackToLogin={() => setForgotPassword(false)} />
      </AuthComponentWrapper>;
    }

    if (!state?.user && signup) {
      // Render sign-in UI
      return <AuthComponentWrapper>
        <RegisterAccount setSignup={setSignup} />
      </AuthComponentWrapper>;
    }

    if (!state?.user) {
      // Render sign-in UI
      return (
        <AuthComponentWrapper>
          <Login
            setForgotPassword={setForgotPassword}
            onRegister={() => setSignup(true)}
            onSignIn={handleSignIn}
          />
        </AuthComponentWrapper>
      );
    }

    // User is authenticated, render the wrapped component
    return <WrappedComponent {...props} />;
  };

  return Authenticator;
};

export default withAuthenticator;
