import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import { AuthStore } from "../../context/authContext";
import api from "../../api/api";
import Loading from "../../components/Loading";
import imageCompression from "browser-image-compression";
import VisibilityIcon from "@mui/icons-material/Visibility";
import axios from "axios";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TransitionsModal from "../../components/Modal";
import QRCode from "react-qr-code";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Image from "../../components/Image";

const Profile = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [images, setImages] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const { state } = AuthStore();
  const [loading, setLoading] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  const [values, setValues] = useState({
    ...state.profile,
  });

  const handleImageSelect = (e) => {
    const { name, files } = e.target;

    setImages({ ...images, [name]: files[0] });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleImageUpload = async () => {
    const uploadedImage = {};
    const portfolio = [];
    const keys = [
      `profiles/${state.profile._id}`,
      `banners/${state.profile._id}`,
      ...selectedImages.map(
        (_, i) => `portfolioImages/${state.profile._id}/${i}`
      ),
    ];

    const res = await api.post("/user/uploadUrls", { keys });

    await Promise.all(
      res.data.urls.map(async (url, i) => {
        const config = {
          maxSizeMB: 7,
          maxWidthOrHeight: i <= 1 ? 250 : 1600,
          useWebWorker: true,
        };

        let image;

        if (images?.profile && i === 0) {
          image = await imageCompression(images.profile, config);
          uploadedImage.profile = url.split("?")[0];
        } else if (images?.banner && i === 1) {
          image = await imageCompression(images.banner, config);
          uploadedImage.banner = url.split("?")[0];
        } else if (i > 1) {
          image = await imageCompression(selectedImages[i - 2], config);
          portfolio.push(url.split("?")[0]);
        }

        if (image) {
          await axios.put(url, image, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          });
        }
      })
    );

    uploadedImage.portfolioImages = portfolio;
    return uploadedImage;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedImages.length > 9) {
      setSelectedImages([]);
      setMessage({
        type: "error",
        message: "Portfolio Images Cannot Be More Than 9!!",
      });
      setSnackbarOpen(true);
      return;
    }

    try {
      setLoading(true);
      const uploadedImages = await handleImageUpload();

      await api.put(`/user/${state?.profile._id}`, {
        ...values,
        ...uploadedImages,
      });
      setMessage({
        type: "success",
        message: "User updated successfully!!",
      });
      setSelectedImages([]);
      setSnackbarOpen(true);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Something Went Wrong!!",
      });
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <Loading text="Updating..." />;
  }

  return (
    <Box m={"20px"}>
      <TransitionsModal
        handleClose={handleClose}
        open={open}
        customStyle={{
          backgroundColor: "#fff",
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={`${window.location.origin}/studio/${values?.username}`}
          viewBox={`0 0 256 256`}
        />
      </TransitionsModal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>
      <Box
        mt={"20px"}
        p={"20px"}
        sx={{
          backgroundColor: colors.primary[400],
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Header
            title="Profile Details"
            subtitle="Update Your Profile Details"
          />
          <Box>
            <a
              href={`/studio/${values?.username}`}
              target="_blank"
              rel="noreferrer"
            >
              <IconButton>
                <VisibilityIcon />
              </IconButton>
            </a>
            <IconButton onClick={handleOpen}>
              <QrCodeIcon />
            </IconButton>
          </Box>
        </Box>

        <form method="post" onSubmit={handleSubmit}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              position: "relative",
              marginTop: "20px",
            }}
          >
            <label htmlFor="banner">
              <Box
                component="div"
                sx={{
                  width: "100%",
                  height: isNonMobile ? "18vw" : "30vw",
                  borderRadius: "5px",
                  marginBottom: isNonMobile ? "-80px" : "-50px",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <Image
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                  alt="banner"
                  src={
                    images?.banner
                      ? URL.createObjectURL(images?.banner)
                      : values?.banner
                      ? values?.banner
                      : "https://dummyimage.com/1600x300/ddd/aaa"
                  }
                />
                <EditOutlinedIcon
                  sx={{
                    position: "absolute",
                    top: "4px",
                    right: "8px",
                    backgroundColor: "#ddd",
                    color: "#000",
                    padding: "4px",
                    borderRadius: "100%",
                  }}
                />
              </Box>
              <input
                accept="image/*"
                style={{ display: "none" }}
                id="banner"
                type="file"
                name="banner"
                onChange={handleImageSelect}
              />
            </label>
            <label
              style={{
                height: isNonMobile ? "12vw" : "18vw",
                width: isNonMobile ? "12vw" : "18vw",
              }}
              htmlFor="profile"
            >
              <Box
                component="div"
                sx={{
                  height: isNonMobile ? "12vw" : "18vw",
                  width: isNonMobile ? "12vw" : "18vw",

                  position: "absolute",
                  top: isNonMobile ? "11vw" : "20vw",
                  left: "6vw",
                  overflow: "hidden",
                  cursor: "pointer",
                }}
              >
                <Image
                  style={{
                    height: isNonMobile ? "12vw" : "18vw",
                    width: isNonMobile ? "12vw" : "18vw",
                    objectFit: "cover",
                    border: "2px solid #ddd",
                    borderRadius: "100%",
                  }}
                  alt="profile"
                  src={
                    images?.profile
                      ? URL.createObjectURL(images?.profile)
                      : values?.profile
                      ? values?.profile
                      : "https://dummyimage.com/200x200/ddd/aaa"
                  }
                />
                <EditOutlinedIcon
                  sx={{
                    bottom: "2px",
                    right: "2vw",
                    zIndex: 100,
                    position: "absolute",
                    backgroundColor: "#ddd",
                    color: "#000",
                    padding: "4px",
                    borderRadius: "100%",
                  }}
                />
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="profile"
                  type="file"
                  name="profile"
                  onChange={handleImageSelect}
                />
              </Box>
            </label>
          </Box>
          <Grid container spacing={2} my={1} mb={3}>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Business/Studio Name"
                onChange={handleChange}
                value={values.businessName || ""}
                name="businessName"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Your Name"
                onChange={handleChange}
                value={values.ownerName || ""}
                name="ownerName"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={12} item>
              <TextField
                fullWidth
                variant="outlined"
                multiline
                rows={3}
                type="text"
                label="About Business/Studio"
                onChange={handleChange}
                value={values.aboutBusiness || ""}
                name="aboutBusiness"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Email"
                disabled
                onChange={handleChange}
                value={values.email || ""}
                name="email"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="tel"
                label="Phone"
                disabled
                onChange={handleChange}
                value={values.phone || ""}
                name="phone"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Instagram Id"
                onChange={handleChange}
                value={values.instagram || ""}
                name="instagram"
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>

            <Grid xs={12} sm={12} lg={6} item>
              <Autocomplete
                multiple
                options={[]}
                freeSolo
                filterSelectedOptions
                onChange={(event, value) =>
                  setValues({
                    ...values,
                    videoUrls: value,
                  })
                }
                value={values.videoUrls || []}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Video URLs"
                  />
                )}
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": { color: "inherit !important" },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>

            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Address"
                onChange={handleChange}
                value={values.address || ""}
                name="address"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="City"
                onChange={handleChange}
                value={values.city || ""}
                name="city"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="Country"
                onChange={handleChange}
                value={values.country || ""}
                name="country"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
            <Grid xs={12} sm={12} lg={6} item>
              <TextField
                fullWidth
                variant="outlined"
                type="text"
                label="ZIP"
                onChange={handleChange}
                value={values.zip || ""}
                name="zip"
                required
                sx={{
                  gridColumn: "span 2",
                  "& label.Mui-focused": {
                    color: "inherit !important",
                  },
                  "& .MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#fff", // Changes outline color when focused
                    },
                  },
                }}
              />
            </Grid>
          </Grid>

          <Box>
            <input
              accept="image/*"
              style={{ display: "none" }}
              id="image-upload"
              type="file"
              multiple
              onChange={(e) =>
                setSelectedImages([...selectedImages, ...e.target.files])
              }
            />
            <label htmlFor={"image-upload"}>
              <Button
                variant="contained"
                color="neutral"
                component="span"
                startIcon={<CloudUploadIcon />}
              >
                Select Portfolio Images
              </Button>
            </label>
          </Box>
          {selectedImages.length ? (
            <Typography mt={3}>
              {selectedImages.length} portfolio images are selected!!
            </Typography>
          ) : (
            <></>
          )}
          <Box
            display="flex"
            justifyContent="end"
            mt={"20px"}
            sx={{ gridColumn: "span 2" }}
          >
            <Button type="submit" color="secondary" variant="contained">
              Update Profile
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default Profile;
