import React from 'react'
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { AddCircleOutline } from "@mui/icons-material";
import { Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import Header from '../../../components/Header';
import { tokens } from '../../../theme';
import DashboardCard from '../../../components/DashboardCard';
import PermMediaIcon from "@mui/icons-material/PermMedia";


const Top = ({ totalProjects, availableCredits, projectsUnderSelection, setProjectModal }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isNonMobile = useMediaQuery("(min-width:600px)");

    return (
        <div>
            {/* HEADER */}
            <Box
                sx={
                    isNonMobile
                        ? {
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            marginBottom: "60px",
                        }
                        : {
                            marginBottom: "30px",
                        }
                }
            >
                <Header title="DASHBOARD" subtitle="Welcome to PhotoSelection.Asia" />

                <Button
                    onClick={() => setProjectModal(true)}
                    sx={{
                        backgroundColor: colors.blueAccent[700],
                        color: colors.grey[100],
                        fontSize: "14px",
                        fontWeight: "bold",
                        padding: "10px 20px",
                        marginTop: isNonMobile ? 0 : "20px",
                    }}
                >
                    <AddCircleOutline sx={{ mr: "5px" }} />
                    Create Project
                </Button>
            </Box>

            <Grid container gap={3}>
                <DashboardCard
                    title="Total Projects"
                    value={totalProjects}
                    backgroundColor={colors.primary[400]}
                    icon={<PermMediaIcon fontSize="large" />}
                />
                <DashboardCard
                    title="Available Credits"
                    value={availableCredits || 0}
                    backgroundColor={colors.primary[400]}
                    icon={<CreditCardIcon fontSize="large" />}
                />
                <DashboardCard
                    title="Under Selection"
                    value={projectsUnderSelection}
                    backgroundColor={colors.primary[400]}
                    icon={<CheckCircleIcon fontSize="large" />}
                />
            </Grid>
        </div>
    )
}

export default Top