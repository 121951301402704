import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { Auth } from "aws-amplify";
import { CheckCircleOutline } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

function ForgotPassword({ onBackToLogin }) {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordChanged, setPasswordChanged] = useState(false);

  useEffect(() => {
    if (errorMessage !== "") {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000); // Clear error message after 3 seconds

      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const initiatePasswordChange = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      await Auth.forgotPassword(values?.username);
      setOtpSent(true);
    } catch (error) {
      switch (error.code) {
        case "UserNotFoundException":
          setErrorMessage("User Not Found");
          break;
        case "NotAuthorizedException":
          setErrorMessage("Password cannot be changed");
          break;
        case "LimitExceededException":
          setErrorMessage("Attempt limit exceeded, please try after some time");
          break;
        default:
          setErrorMessage("Something went wrong");
          break;
      }
    }
    setLoading(false);
  };

  const completePasswordChange = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await Auth.forgotPasswordSubmit(
        values?.username,
        values?.otp,
        values?.password
      );
      setPasswordChanged(true);
    } catch (error) {
      switch (error.code) {
        case "InvalidPasswordException":
          setErrorMessage("Password should have more that 8 characters");
          break;
        case "NotAuthorizedException":
          setErrorMessage("Invalid Session, login again");
          break;
        case "ExpiredCodeException":
          setErrorMessage("Invalid code provided, please request a code again");
          break;
        case "CodeMismatchException":
          setErrorMessage("Invalid Verification Code");
          break;
        default:
          setErrorMessage("Something went wrong");
          console.log(error);
          break;
      }
      console.log(error);
    }
    setLoading(false);
  };

  return (
    <div style={styles.container}>
      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(#1845ad, #23a2f6)",
          left: "-80px",
          top: "-80px",
        }}
      ></div>
      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(to right, #ff512f, #f09819)",
          right: "-30px",
          bottom: "-80px",
        }}
      ></div>
      {passwordChanged ? (
        <div
          style={{
            ...styles.form,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              marginBottom: 6,
            }}
          >
            <CheckCircleOutline
              sx={{
                fontSize: 100,
                color: "#ff6600",
              }}
            />
            <Typography variant="h4">Password Changed!</Typography>
            <Typography
              sx={{
                fontSize: "18px",
                color: "grey",
              }}
            >
              Your password has been changed successfully.
            </Typography>
          </Box>
        </div>
      ) : (
        <form
          method="post"
          onSubmit={otpSent ? completePasswordChange : initiatePasswordChange}
          style={{
            ...styles.form,
            height: otpSent ? styles.form.height : "400px",
          }}
        >
          <h3 style={styles.header}>Reset Password</h3>
          {otpSent ? (
            <div>
              <label htmlFor="otp" style={styles.label}>
                Otp
              </label>
              <input
                type="text"
                placeholder="otp"
                required
                name="otp"
                value={values.otp || ""}
                onChange={handleInputChange}
                style={styles.input}
              />
              <label htmlFor="password" style={styles.label}>
                Password
              </label>
              <input
                type="password"
                placeholder="Password"
                required
                name="password"
                value={values.password || ""}
                onChange={handleInputChange}
                style={styles.input}
              />
            </div>
          ) : (
            <div>
              <label htmlFor="username" style={styles.label}>
                Username
              </label>
              <input
                type="text"
                placeholder="Username"
                required
                name="username"
                value={values.username || ""}
                onChange={handleInputChange}
                style={styles.input}
              />
            </div>
          )}
          {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}

          <button type="submit" disabled={loading} style={styles.button}>
            {loading ? (
              "Verifying..."
            ) : (
              <>{otpSent ? "Confirm" : "Send Verification Code"}</>
            )}
          </button>
          <div
            style={{
              ...styles.nav,
              justifyContent: "end",
              marginRight: 0,
            }}
          >
            <div
              onClick={onBackToLogin}
              style={{ ...styles.navButton, marginRight: 0 }}
            >
              Back to Login
            </div>
          </div>
        </form>
      )}
    </div>
  );
}

export default ForgotPassword;
