import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";

export default function TransitionsModal({
  children,
  open,
  handleClose,
  width,
  customStyle = {},
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: width || 400,
    bgcolor: "grey",
    //border: '2px solid #000',
    borderRadius: "5px",
    boxShadow: 24,
    p: 4,
  };
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
      >
        <Fade in={open}>
          <Box sx={[style, customStyle]}>{children}</Box>
        </Fade>
      </Modal>
    </div>
  );
}
