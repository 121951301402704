import { Box, Typography } from "@mui/material";
import React from "react";

const About = ({ profileDetails }) => {
  return (
    <Box sx={{ padding: "15px 20px" }}>
      <Typography
        variant="h1"
        sx={{
          fontSize: "2rem",
          fontWeight: "500",
          textTransform: "capitalize",
          color: "rgba(0,0,0,0.8)",
          marginBottom: "5px",
        }}
      >
        {profileDetails?.businessName}
      </Typography>
      <Typography>{profileDetails?.aboutBusiness}</Typography>
    </Box>
  );
};

export default About;
