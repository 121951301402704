import React, { useEffect, useState } from "react";
import { Box, Grid, IconButton, Typography, useTheme } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FolderIcon from "@mui/icons-material/Folder";
import { tokens } from "../../../theme";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image from "../../../components/Image";

const ImageContainer = ({
  images,
  folders,
  projectId,
  view = "admin",
  photoComponent,
}) => {
  const theme = useTheme();
  const colors = tokens(theme?.palette?.mode);
  const [initialLoadCount, setInitialLoadCount] = useState(20);
  const [loadedImages, setLoadedImages] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState("");
  const [folderTree, setFolderTree] = useState(createJsonTree(folders));
  const [totalImages, setTotalImages] = useState(0);
  const loadNext = () => {
    const remainingImages = totalImages - loadedImages?.length;
    const nextBatch = remainingImages > 20 ? 20 : remainingImages;
    setInitialLoadCount((prevCount) => prevCount + nextBatch);
  };

  useEffect(() => {
    const filteredImages =
      images?.filter(
        (i) =>
          decodeURIComponent(i)
            ?.split(`${projectId}/`)[1]
            ?.split("/")
            ?.slice(0, -1)
            ?.join("/") === selectedFolder
      ) || [];
    setLoadedImages(filteredImages?.slice(0, initialLoadCount));
    setTotalImages(filteredImages.length);
  }, [initialLoadCount, images, selectedFolder, projectId]);

  function createJsonTree(paths) {
    const tree = {};

    paths?.forEach((path) => {
      const segments = path?.split("/");

      let currentNode = tree;
      segments?.forEach((segment) => {
        if (!currentNode[segment]) {
          currentNode[segment] = {};
        }
        currentNode = currentNode[segment];
      });
    });

    return tree;
  }

  function getChildTree(parentKey, tree) {
    const segments = parentKey?.split("/");
    let currentNode = tree;

    for (const segment of segments) {
      if (currentNode[segment]) {
        currentNode = currentNode[segment];
      } else {
        return null; // Parent key doesn't exist in the tree
      }
    }

    return currentNode;
  }

  const handleFolderClick = (folder) => {
    setSelectedFolder(
      `${selectedFolder === "" ? selectedFolder : `${selectedFolder}/`
      }${folder}`
    );
    setFolderTree(folderTree[folder]);
    setInitialLoadCount(20);
  };

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        {["Home", ...selectedFolder?.split("/")]?.map((folder, i) =>
          folder ? (
            <Box
              key={i}
              onClick={() => {
                setInitialLoadCount(20);
                if (folder === "Home") {
                  setSelectedFolder("");
                  return setFolderTree(createJsonTree(folders));
                }
                setSelectedFolder(
                  selectedFolder?.split("/")?.slice(0, i)?.join("/")
                );
                setFolderTree(
                  getChildTree(
                    selectedFolder?.split("/")?.slice(0, i)?.join("/"),
                    createJsonTree(folders)
                  )
                );
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                margin: "15px 0",
                cursor: "pointer",
                ":hover": {
                  textDecoration: "underline",
                },
              }}
            >
              <Typography variant={"h5"}>{folder}</Typography>
              <ArrowForwardIosIcon
                sx={{
                  fontSize: "16px",
                  margin: "0 5px",
                }}
              />
            </Box>
          ) : (
            <div key={i}></div>
          )
        )}
      </Box>
      {view !== "user" ? (
        <Grid container spacing={1} style={{ marginTop: "12px" }}>
          {Object?.keys(folderTree)?.map((folder, i) => (
            <Grid
              onClick={() => {
                handleFolderClick(folder);
              }}
              key={i}
              sx={{
                position: "relative",
              }}
              item
            >
              <FolderIcon
                sx={{
                  width: "170px",
                  height: "100%",
                  borderRadius: "4px",
                  backgroundColor: colors?.primary[300],
                  cursor: "pointer",
                }}
              />
              <Typography
                sx={{
                  position: "absolute",
                  color: "#000",
                  top: "54%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                }}
              >
                {folder}
              </Typography>
            </Grid>
          ))}
          {loadedImages?.map((image, index) => (
            <Grid item key={index}>
              {view === "selectedImages" ? (
                photoComponent(image, index)
              ) : (
                <Image
                  src={image}
                  alt={`${index + 1}`}
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover",
                    borderRadius: "4px",
                  }}
                />
              )}
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box mx={2}>
          <Grid container spacing={3}>
            {Object.keys(folderTree).map((folder, i) => (
              <Grid
                onClick={() => {
                  handleFolderClick(folder);
                }}
                key={i}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                xl={2}
                sx={{
                  position: "relative",
                }}
                item
              >
                <FolderIcon
                  sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: colors.primary[300],
                    borderRadius: "3px",
                    cursor: "pointer",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    color: "#000",
                    top: "54%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  {folder}
                </Typography>
              </Grid>
            ))}
            {loadedImages.map((image, index) => (
              <Grid xs={12} sm={6} md={4} lg={3} xl={2} item key={index}>
                {photoComponent(image)}
              </Grid>
            ))}
          </Grid>
        </Box>
      )}
      {loadedImages?.length !== totalImages && (
        <Box
          sx={{
            textAlign: "center",
            marginTop: "30px",
            cursor: "pointer",
          }}
          onClick={loadNext}
        >
          <Box>Load More</Box>
          <IconButton>
            <ExpandMoreIcon />
          </IconButton>
        </Box>
      )}
    </div>
  );
};

export default ImageContainer;
