import React, { useState } from "react";
import { Card, useTheme, Typography, Box } from "@mui/material";
import { tokens } from "../../../theme";
import TransitionsModal from "../../../components/Modal";
import Image from "../../../components/Image";

const SelectedPhotoCard = ({ image, comment }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  return (
    <Card
      sx={{
        backgroundColor: colors.primary[400],
        width: "170px",
        padding: "0px 10px 0px 10px",
      }}
    >
      <TransitionsModal handleClose={handleClose} open={open}>
        <Box sx={{ textAlign: "center" }}>
          <Typography>{comment ? comment : "No Comments"}</Typography>
        </Box>
      </TransitionsModal>
      <Box
        sx={{
          alignItems: "center",
          margin: "10px",
        }}
      >
        <Typography noWrap>
          {decodeURIComponent(image.split("/").slice(-1)[0])}
        </Typography>
      </Box>
      <Image
        src={image}
        alt={image}
        style={{
          width: "150px",
          height: "150px",
          objectFit: "cover",
          borderRadius: "4px",
        }}
      />

      <Box
        sx={{
          marginBottom: "10px",
          marginTop: "10px",
        }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <Typography
          noWrap
          style={{
            height: "25px",
            padding: "4px 10px",
            borderRadius: "5px",
            borderWidth: "0",
            width: "100%",
            backgroundColor: colors.primary[400],
            cursor: "pointer",
            outline: "none",
            color: colors.grey[300],
            fontSize: "12px",
          }}
        >
          {comment ? comment : "No Comments"}
        </Typography>
      </Box>
    </Card>
  );
};

export default SelectedPhotoCard;
