import styled from "@emotion/styled";
import { Box, Grid, IconButton, Tab, Tabs, useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";

import Contact from "./components/Contact";
import About from "./components/About";
import Photos from "./components/Photos";
import Videos from "./components/Videos";
import Profile from "./components/Profile";
import { ColorModeContext } from "../../theme";
import Loading from "../../components/Loading";
import axios from "axios";
import { backendBaseUrl } from "../../constants/constants";
import { useParams } from "react-router-dom";
import PageNotFound from "../pagenotfound/PageNotFound";
import QrCodeIcon from "@mui/icons-material/QrCode";
import TransitionsModal from "../../components/Modal";
import QRCode from "react-qr-code";
import Image from "../../components/Image";

const StudioProfile = () => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const isMidScreen = useMediaQuery("(min-width:1200px)");
  const colorMode = useContext(ColorModeContext);
  const { studioId } = useParams();
  const [values, setValues] = useState({});
  const [selectedTab, setSelectedTab] = useState("About");
  const [loading, setLoading] = useState(false);
  const [notFound, setNotFound] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };

  useEffect(() => {
    const loadStudioDetails = async () => {
      try {
        setLoading(true);
        const res = await axios.get(`${backendBaseUrl}/studio/${studioId}`);
        setValues(res.data.studio._doc);
      } catch (error) {
        console.log(error);
        setNotFound(true);
      } finally {
        setLoading(false);
      }
    };
    loadStudioDetails();
    colorMode.setLightMode();
  }, [colorMode, studioId]);

  const StyledTabs = styled((props) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
      variant="scrollable"
      sx={{
        borderBottom: "0.5px solid #e9ebee",
      }}
    />
  ))({
    "& .MuiTabs-indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
    },
    "& .MuiTabs-indicatorSpan": {
      width: "100%",
      backgroundColor: "#635ee7",
    },
  });

  const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: "none",
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.typography.pxToRem(18),
      marginRight: theme.spacing(1),
      color: "rgba(0, 0, 0, 0.7)",
      "&.Mui-selected": {
        color: "#000",
      },
      "&.Mui-focusVisible": {
        backgroundColor: "rgba(100, 95, 228, 0.32)",
      },
    })
  );

  if (loading) return <Loading />;
  if (notFound) return <PageNotFound />;

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#e9ebee",
        color: "#000",
      }}
    >
      <IconButton
        sx={{
          position: "absolute",
          width: "30px",
          height: "30px",
          backgroundColor: "#fff",
          zIndex: 10,
          right: "20px",
          top: "5px",
        }}
        onClick={handleOpen}
      >
        <QrCodeIcon
          sx={{
            paddingTop: "2px",
          }}
          fontSize="small"
        />
      </IconButton>
      <TransitionsModal
        handleClose={handleClose}
        open={open}
        customStyle={{
          backgroundColor: "#fff",
        }}
      >
        <QRCode
          size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={`${window.location.origin}/studio/${values?.username}`}
          viewBox={`0 0 256 256`}
        />
      </TransitionsModal>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box
          component="div"
          sx={{
            width: "100%",
            height: isNonMobile ? "18vw" : "30vw",
          }}
        >
          <Image
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            alt="banner"
            src={
              values?.banner
                ? values?.banner
                : "https://dummyimage.com/1600x300/ddd/aaa"
            }
          />
        </Box>

        <Box
          component="div"
          sx={{
            height: isNonMobile ? "12vw" : "18vw",
            width: isNonMobile ? "12vw" : "18vw",
            border: "2px solid #ddd",
            borderRadius: "100%",
            position: "absolute",
            top: isNonMobile ? "11vw" : "20vw",
            left: "7vw",
            overflow: "hidden",
            boxShadow: "0px 3px 10px #1d2d4f",
          }}
        >
          <Image
            style={{
              height: isNonMobile ? "12vw" : "18vw",
              width: isNonMobile ? "12vw" : "18vw",
              objectFit: "cover",
            }}
            alt="banner"
            src={
              values?.profile
                ? values?.profile
                : "https://dummyimage.com/200x200/ddd/aaa"
            }
          />
        </Box>
      </Box>
      <Grid container>
        <Grid
          sx={{
            paddingTop: isNonMobile ? "6.5vw" : "10vw",
          }}
          item
          xs={12}
          md={4}
          lg={3.4}
        >
          <Profile profileDetails={values} />
        </Grid>
        <Grid
          sx={{
            backgroundColor: "#fff",
          }}
          item
          xs={12}
          md={8}
          lg={5}
        >
          <StyledTabs
            value={selectedTab}
            onChange={(_, value) => setSelectedTab(value)}
            aria-label="tabs"
          >
            <StyledTab value={"About"} label="About" />
            <StyledTab value={"Photos"} label="Photos" />
            <StyledTab value={"Videos"} label="Videos" />
          </StyledTabs>
          {selectedTab === "About" && <About profileDetails={values} />}
          {selectedTab === "Photos" && (
            <Photos photos={values?.portfolioImages} />
          )}
          {selectedTab === "Videos" && <Videos videos={values?.videoUrls} />}
        </Grid>
        <Grid
          sx={{
            minHeight: "70vh",
            backgroundColor: "#fff",
            marginTop: isMidScreen ? 0 : "30px",
          }}
          item
          xs={12}
          md={12}
          lg={3.6}
          pb={3}
          pt={1.5}
        >
          <Contact id={values?._id} />
        </Grid>
      </Grid>
    </div>
  );
};

export default StudioProfile;
