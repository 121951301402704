import { Box } from '@mui/material'
import React from 'react'
import Header from '../../components/Header';
import Dashboard from '../dashboard';

const UserProjectList = ({ userDetails }) => {

    return (
        <Box
            m={"20px"}

        >
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}>
                <Header
                    title="Project List"
                />

            </Box>

            <Box sx={{
                margin: '-20px'
            }}>
                <Dashboard hasHeader={false} />
            </Box>
        </Box>
    )
}

export default UserProjectList