import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Box, Typography } from "@mui/material";

const Profile = ({ profileDetails }) => {
  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        margin: "10px 30px",
        padding: "15px 20px",
        borderRadius: "5px",
        marginBottom: "30px",
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: "2rem",
          fontWeight: "500",
          textTransform: "capitalize",
          color: "rgba(0,0,0,0.8)",
        }}
      >
        {profileDetails?.name}
      </Typography>
      <Typography>
        {profileDetails?.businessName} | {profileDetails?.address},{" "}
        {profileDetails?.city}, {profileDetails?.country} ({profileDetails?.zip}
        )
      </Typography>

      <Box
        sx={{
          display: "flex",
          marginTop: "5px",
        }}
      >
        <EmailIcon
          sx={{
            color: "rgba(0,0,0,0.6)",
          }}
          fontSize="small"
        />
        <Typography
          sx={{
            verticalAlign: "center",
            marginLeft: "5px",
          }}
        >
          {profileDetails?.email}
        </Typography>
      </Box>
      {profileDetails?.instagram && (
        <Box
          sx={{
            display: "flex",
            marginTop: "5px",
          }}
        >
          <InstagramIcon
            sx={{
              color: "rgba(0,0,0,0.6)",
            }}
            fontSize="small"
          />
          <Typography
            sx={{
              verticalAlign: "center",
              marginLeft: "5px",
            }}
          >
            {profileDetails?.instagram}
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: "flex",
          marginTop: "5px",
        }}
      >
        <PhoneIcon
          sx={{
            color: "rgba(0,0,0,0.6)",
          }}
          fontSize="small"
        />
        <Typography
          sx={{
            verticalAlign: "center",
            marginLeft: "5px",
          }}
        >
          {profileDetails?.phone}
        </Typography>
      </Box>
    </Box>
  );
};

export default Profile;
