import { useEffect, useState } from "react";
import { ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { tokens } from "../theme";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import { AuthStore } from "../context/authContext";
import placeholderImage from "../assets/user.png";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HistoryIcon from "@mui/icons-material/History";
import Image from "./Image";
import logo from "../assets/logo.png"

const defaultMenu = [
  {
    title: "",
    subItems: [
      {
        title: "Dashboard",
        icon: <HomeOutlinedIcon />,
        to: "/",
        permissionRequired: [],
      },
    ],
  },
  {
    title: "",
    subItems: [
      {
        title: "Buy Credits",
        icon: <CreditCardIcon fontSize="small" />,
        to: "/credits",
        permissionRequired: [],
      },
    ],
  },
  {
    title: "",
    subItems: [
      {
        title: "Credit History",
        icon: <HistoryIcon fontSize="small" />,
        to: "/credits/history",
        permissionRequired: [],
      },
    ],
  },
  {
    title: "",
    subItems: [
      {
        title: "Inquiries",
        icon: <HelpOutlineIcon fontSize="small" />,
        to: "/inquiry",
        permissionRequired: [],
      },
    ],
  },
  {
    title: "",
    subItems: [
      {
        title: "View Users",
        icon: <PeopleOutlineIcon fontSize="small" />,
        to: "/users",
        permissionRequired: ['admin', 'super_admin'],
      },
    ],
  },
  {
    title: "",
    subItems: [
      {
        title: "Profile",
        icon: <PersonOutlineIcon />,
        to: "/profile",
        permissionRequired: [],
      },
    ],
  },
  {
    title: "",
    subItems: [
      {
        title: "FAQ",
        icon: <QuizOutlinedIcon />,
        to: "/faq",
        permissionRequired: [],
      },
    ],
  },
]

const Item = ({ title, to, icon, active, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={active}
      style={{
        color: colors.grey[100],
      }}
      onClick={onClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = ({ searchTerm = "", setSidebarVisible = () => { }, profileImage = placeholderImage, profileName = 'User', sideBarMenu = defaultMenu }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("/");
  const { state, signOut } = AuthStore();

  useEffect(() => {
    setSelected(window.location.pathname);
  }, []);

  const filteredItems = sideBarMenu.filter(
    (item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      (item.subItems &&
        item.subItems.some((subItem) =>
          subItem.title.toLowerCase().includes(searchTerm.toLowerCase())
        ))
  );

  const hasPermission = (permissionRequired = []) => {
    // Check if the user has any of the required permissionRequired
    if (!permissionRequired.length) return true;

    return permissionRequired?.some((permission) =>
      state.profile.roles.includes(permission)
    );
  };

  return (
    <Box
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => {
              setIsCollapsed(!isCollapsed);
              setSidebarVisible(false);
            }}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img
                  alt="logo"
                  src={logo}
                  style={{
                    width: "85%",
                    objectFit: 'contain',
                    marginBottom: '6px'
                  }}
                />
                {/* <Typography variant="h4" color={colors.grey[100]}>
                  PhotoSelection.Asia
                </Typography> */}
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Image
                  alt="profile-user"
                  src={profileImage}
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    boxShadow: "0px 3px 10px #1d2d4f",
                    border: "2px solid #ddd",
                    width: "100px",
                    height: "100px",
                  }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {profileName}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {state?.profile?.designation}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {filteredItems.map(
              (item, i) =>
                hasPermission(item.permissionRequired) && (
                  <Box key={item.title + i}>
                    <Typography
                      variant="h6"
                      color={colors.grey[300]}
                      sx={{ m: "15px 0 5px 20px" }}
                    >
                      {item.title}
                    </Typography>
                    {item.subItems.length && (
                      <Box marginTop="-5px">
                        {item.subItems.map(
                          (subItem) =>
                            hasPermission(subItem.permissionRequired) && (
                              <Item
                                key={subItem.title}
                                title={subItem.title}
                                to={subItem.to}
                                icon={subItem.icon}
                                active={selected === subItem.to}
                                onClick={() => setSelected(subItem.to)}
                              />
                            )
                        )}
                      </Box>
                    )}
                  </Box>
                )
            )}
            <Box
              sx={{
                marginTop: "10px",
              }}
            >
              <Item
                icon={
                  <LogoutIcon fontSize="small" sx={{ marginLeft: "3px" }} />
                }
                onClick={() => signOut()}
                title={"Logout"}
                active={false}
              />
            </Box>
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
