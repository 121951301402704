import axios from "axios";
import { Auth } from "aws-amplify";
import { backendBaseUrl } from "../constants/constants";

const api = axios.create({
  baseURL: backendBaseUrl,
});

api.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    if (accessToken) {
      config.headers.authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

export default api;
