import { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Snackbar,
  Alert,
} from "@mui/material";
import axios from "axios";
import { backendBaseUrl } from "../../../constants/constants";

export default function Contact({ id }) {
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await axios.post(`${backendBaseUrl}/contact`, {
        ...values,
        user: id,
      });
      setValues({});
      setMessage({
        type: "success",
        message: "Submitted Successfully!!",
      });
      setSnackbarOpen(true);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Something Went Wrong!!",
      });
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="h3" align="center" mb={2}>
          Contact Us
        </Typography>
        <form method="post" onSubmit={handleSubmit}>
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Name"
            onChange={handleChange}
            value={values.name || ""}
            name="name"
            required
            margin="normal"
            placeholder="Name"
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Phone"
            onChange={handleChange}
            value={values.phone || ""}
            name="phone"
            required
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            type="email"
            label="Email"
            onChange={handleChange}
            value={values.email || ""}
            name="email"
            required
            margin="normal"
          />
          <TextField
            fullWidth
            variant="outlined"
            type="text"
            label="Subject"
            onChange={handleChange}
            value={values.subject || ""}
            name="subject"
            required
            margin="normal"
          />

          <TextField
            fullWidth
            label="Message"
            value={values?.message || ""}
            onChange={handleChange}
            margin="normal"
            name="message"
            required
            multiline
            rows={4}
          />
          <Button
            fullWidth
            disabled={loading}
            type={loading ? "Submitting..." : "submit"}
            sx={{
              mt: 2,
              backgroundColor: "#000",
              color: "#fff",
              "&:hover": {
                backgroundColor: "#111",
              },
            }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Box>
  );
}
