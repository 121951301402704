import React, { useState } from "react";
import { styled } from "@mui/system";
import { Grid, Container, useMediaQuery } from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Carousel from "./Carousel";
import TransitionsModal from "../../../components/Modal";

const defaultImages = [
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
  "https://dummyimage.com/200x200/ddd/aaa",
];

const ImgWrapper = styled("img")({
  width: "100%",
  height: "auto",
  display: "block",
  cursor: "pointer",
});

const PhotoItem = styled("div")({
  position: "relative",
  color: "#fff",
  "&:hover > div": {
    opacity: 1,
    cursor: "pointer",
  },
});

const PhotoTextMore = styled("div")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  opacity: 0,
  transition: "opacity 0.3s ease-in-out",
});

const Photos = ({ photos = defaultImages }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleOpen = (index) => {
    setSelectedIndex(index);
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    setOpen(false);
  };
  return (
    <Container
      sx={{
        margin: "20px 0",
      }}
      maxWidth="lg"
    >
      <TransitionsModal
        handleClose={handleClose}
        open={open}
        customStyle={{
          backgroundColor: "#fff",
          width: isNonMobile ? "60vw" : "100vw",
        }}
      >
        <Carousel images={photos} selectedIndex={selectedIndex} />
      </TransitionsModal>
      <Grid container spacing={3}>
        {photos.map((image, index) => (
          <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
            <PhotoItem onClick={() => handleOpen(index)}>
              <ImgWrapper src={image} alt={`Image ${index + 1}`} />
              <PhotoTextMore>
                <VisibilityIcon fontSize="small" />
              </PhotoTextMore>
            </PhotoItem>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Photos;
