import { Box, Button, Typography, useTheme } from "@mui/material";
import React from "react";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import CryptoJS from "crypto-js";
import { AuthStore } from "../../context/authContext";
import {
  backendBaseUrl,
  payUAccessKey,
  payUSecretKey,
  paymentAPI,
} from "../../constants/constants";

const CreditDetails = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { state } = AuthStore();

  const styles = {
    card: {
      height: "420px",
      width: "300px",
      backgroundColor: "rgba(255,255,255,0.13)",
      borderRadius: "10px",
      backdropFilter: "blur(10px)",
      border: "2px solid rgba(255,255,255,0.1)",
      boxShadow: "0 0 40px rgba(8,7,16,0.6)",
      padding: "30px",
      margin: "20px",
    },
    container: {
      display: "flex",
      flexWrap: "wrap",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    cardHeader: {
      borderBottom: `0.5px solid ${colors.grey[600]}`,
    },
    cardHeaderText: {
      color: colors.grey[300],
      textAlign: "center",
      fontWeight: "600",
    },
    priceText: {
      color: colors.grey[100],
      textAlign: "center",
      fontWeight: "600",
      margin: "20px",
    },
    cardBody: {
      paddingTop: "30px",
      color: colors.grey[200],
    },
    list: {
      display: "block",
      listStyleType: "disc",
      marginInlineStart: "15px",
    },
    listItem: {
      display: "list-item",
      marginBottom: "10px",
    },
    button: {
      "--green": "#1BFD9C",
      fontSize: "15px",
      padding: "0.7em 2.7em",
      letterSpacing: "0.06em",
      fontFamily: "inherit",
      borderRadius: "0.6em",
      overflow: "hidden",
      transition: "all 0.3s",
      lineHeight: "1.4em",
      border: "2px solid var(--green)",
      background:
        "linear-gradient(to right, rgba(27, 253, 156, 0.1) 1%, transparent 40%, transparent 60%, rgba(27, 253, 156, 0.1) 100%)",
      color: "var(--green)",
      boxShadow:
        "inset 0 0 10px rgba(27, 253, 156, 0.4), 0 0 9px 3px rgba(27, 253, 156, 0.1)",
      cursor: "pointer",
      ":hover": {
        color: "#82ffc9",
        boxShadow:
          "inset 0 0 10px rgba(27, 253, 156, 0.6), 0 0 9px 3px rgba(27, 253, 156, 0.2)",
      },
      ":before": {
        content: '""',
        position: "absolute",
        left: "-4em",
        width: "4em",
        height: "100%",
        top: "0",
        transition: "transform .4s ease-in-out",
        background:
          "linear-gradient(to right, transparent 1%, rgba(27, 253, 156, 0.1) 40%, rgba(27, 253, 156, 0.1) 60%, transparent 100%)",
      },
      ":hover:before": {
        transform: "translateX(15em)",
      },
      width: "80%",
      position: "absolute",
      bottom: "20px",
      left: "50%",
      transform: "translateX(-50%)",
    },
  };

  const generatePayUHash = (params, merchantSalt) => {
    const hashString = `${params.key}|${params.txnid}|${params.amount}|${params.productinfo}|${params.firstname}|${params.email}|${params.udf1}|${params.udf2}|${params.udf3}||||||||${merchantSalt}`;

    const hashValue = CryptoJS.SHA512(hashString).toString(CryptoJS.enc.Hex);

    // Generate the hashed value using the merchant salt
    return hashValue;
  };

  const generateTxnId = () => {
    // Prefix for transaction ID
    const prefix = "TXN";

    // Get the current timestamp (milliseconds since epoch)
    const timestamp = Date.now().toString();

    // Generate a random number between 1000 and 9999 (four-digit number)
    const randomNumber = Math.floor(Math.random() * 9000) + 1000;

    // Concatenate the prefix, timestamp, and random number to create txnid
    const txnid = `${prefix}_${timestamp}_${randomNumber}`;

    return txnid;
  };

  const params = {
    key: payUAccessKey,
    txnid: generateTxnId(),
    productinfo: "Credit",
    email: `${state.profile?.email}`,
    firstname: `${state.profile?.ownerName}`,
    surl: `${backendBaseUrl}/payment/success`,
    furl: `${backendBaseUrl}/payment/failed`,
    phone: `${state.profile?.phone}`,
    udf1: window.location.origin,
    udf3: state.profile._id,
  };

  function handlePurchase(amount, creditPoints) {
    var form = document.getElementById("paymentForm");

    var amountField = form.querySelector('input[name="amount"]');
    amountField.value = `${amount}.00`;

    var creditField = form.querySelector('input[name="udf2"]');
    creditField.value = `${creditPoints}`;

    var hashField = form.querySelector('input[name="hash"]');
    hashField.value = generatePayUHash(
      {
        ...params,
        amount: `${amount}.00`,
        udf2: creditPoints,
      },
      payUSecretKey
    );

    form.submit();
  }

  return (
    <Box m={"20px"}>
      <form id="paymentForm" action={paymentAPI} method="POST">
        <input type="hidden" name="key" value={params.key} />
        <input type="hidden" name="txnid" value={params.txnid} />
        <input type="hidden" name="productinfo" value={params.productinfo} />
        <input type="hidden" name="amount" value={""} />
        <input type="hidden" name="email" value={params.email} />
        <input type="hidden" name="firstname" value={params.firstname} />
        <input type="hidden" name="surl" value={params.surl} />
        <input type="hidden" name="furl" value={params.furl} />
        <input type="hidden" name="phone" value={params.phone} />
        <input type="hidden" name="hash" value={""} />
        <input type="hidden" name="udf1" value={params.udf1} />
        <input type="hidden" name="udf2" value={""} />
        <input type="hidden" name="udf3" value={params.udf3} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <Header
            headerStyle={{
              fontSize: "25px",
            }}
            title="Choose a Perfect Plan For You"
            subtitle=""
          />
        </Box>
        <Box sx={styles.container}>
          <Box sx={styles.card}>
            <Box sx={styles.cardHeader}>
              <Typography variant="h3" sx={styles.cardHeaderText}>
                Starter
              </Typography>
              <Typography variant="h2" sx={styles.priceText}>
                ₹99
              </Typography>
            </Box>
            <Box sx={styles.cardBody}>
              <ul style={styles.list}>
                <li style={styles.listItem}>1 Credits (1 Project's)</li>
                <li style={styles.listItem}>
                  Credit will be used to create 1 project and can upload upto
                  5000 images. No monthly or hidden cost
                </li>
              </ul>
            </Box>
            <Button onClick={() => handlePurchase(99, 1)} sx={styles.button}>
              Buy
            </Button>
          </Box>
          <Box sx={styles.card}>
            <Box sx={styles.cardHeader}>
              <Typography variant="h3" sx={styles.cardHeaderText}>
                Business
              </Typography>
              <Typography variant="h2" sx={styles.priceText}>
                ₹449
              </Typography>
            </Box>
            <Box sx={styles.cardBody}>
              <ul style={styles.list}>
                <li style={styles.listItem}>5 Credits (5 Project's)</li>
                <li style={styles.listItem}>
                  Credits will be used to create 5 projects and upload upto 5000
                  images in each project. No monthly or extra cost.
                </li>
              </ul>
            </Box>
            <Button onClick={() => handlePurchase(449, 5)} sx={styles.button}>
              Buy
            </Button>
          </Box>
          <Box sx={styles.card}>
            <Box sx={styles.cardHeader}>
              <Typography variant="h3" sx={styles.cardHeaderText}>
                Premium
              </Typography>
              <Typography variant="h2" sx={styles.priceText}>
                ₹999
              </Typography>
            </Box>
            <Box sx={styles.cardBody}>
              <ul style={styles.list}>
                <li style={styles.listItem}>12 Credits (12 Project's)</li>
                <li style={styles.listItem}>
                  Credits will be used to create 12 projects and upload upto
                  5000 images in each project. No monthly or extra cost.
                </li>
                <li style={styles.listItem}>
                  24/7 support Early access to new launch.
                </li>
              </ul>
            </Box>
            <Button onClick={() => handlePurchase(999, 12)} sx={styles.button}>
              Buy
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default CreditDetails;
