// Layout.js
import React, { useState } from "react";
import Sidebar from "./Sidebar";
import Topbar from "./Topbar";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import withAuthenticator from "../screens/auth/withAuthenticator";
import { tokens } from "../theme";
import { AuthStore } from "../context/authContext";

const Layout = ({ children, searchTerm, setSearchTerm }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { state } = AuthStore();

  return (
    <div className="app">
      {isNonMobile && <Sidebar searchTerm={searchTerm} profileImage={state?.profile?.profile} profileName={state?.profile?.ownerName} />}
      {sidebarVisible && (
        <Box
          sx={{
            position: "absolute",
            height: "100vh",
            backgroundColor: colors.primary[400],
            zIndex: 10000,
          }}
        >
          <Sidebar setSidebarVisible={setSidebarVisible} profileImage={state?.profile?.profile} profileName={state?.profile?.ownerName} />
        </Box>
      )}
      <main className="content">
        <Topbar
          handleSearch={(e) => {
            setSearchTerm(e.target.value);
          }}
          handleClick={() => setSidebarVisible(!sidebarVisible)}
        />
        {children}
      </main>
    </div>
  );
};

export default withAuthenticator(Layout);
