import React, { useEffect, useState } from "react";
import { styles } from "./styles";
import { Alert, Box, Grid, Snackbar, useMediaQuery } from "@mui/material";
import axios from "axios";
import { backendBaseUrl } from "../../constants/constants";
import TransitionsModal from "../../components/Modal";
import SuccessPage from "../../components/SuccessPage";

const RegisterAccount = ({ setSignup }) => {
  const isNonMobile = useMediaQuery("(min-width:900px)");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });

  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
  };

  const [values, setValues] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    if (errorMessage !== "") {
      const timer = setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [errorMessage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValidPhoneNumber = /^\d{10}$/.test(values?.phone);

    setLoading(true);
    try {
      if (values.password !== values.confirmPassword) {
        return setErrorMessage("Passwords are not equal!");
      }
      if (!isValidPhoneNumber) {
        return setErrorMessage("Phone number should be a 10-digit number");
      }
      await axios.post(`${backendBaseUrl}/register`, {
        ...values,
      });
      handleOpen();
    } catch (error) {
      setErrorMessage("Something Went Wrong!!");
      setMessage({
        type: "error",
        message: "Something Went Wrong!!",
      });
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      setValues({});
    }
  };

  return (
    <div style={styles.container}>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={7000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleSnackbarClose} severity={message?.type || "info"}>
          {message?.message}
        </Alert>
      </Snackbar>
      <TransitionsModal
        width={isNonMobile ? 600 : "100%"}
        handleClose={handleClose}
        open={open}
        customStyle={{
          backgroundColor: "transparent",
        }}
      >
        <SuccessPage
          buttonText={"Login"}
          message={`Registration has been Completed Successfully. 
          Kindly Check your Email for Username and Password`}
          onButtonClicked={() => setSignup(false)}
        />
      </TransitionsModal>

      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(#1845ad, #23a2f6)",
          left: isNonMobile ? "-330px" : "-80px",
          top: "-100px",
        }}
      ></div>
      <div
        style={{
          ...styles.shape,
          background: "linear-gradient(to right, #ff512f, #f09819)",
          right: isNonMobile ? "-330px" : "-80px",
          bottom: isNonMobile ? "-100px" : "-300px",
        }}
      ></div>

      <form
        method="post"
        onSubmit={handleSubmit}
        style={{
          ...styles.form,
          height: isNonMobile ? "500px" : "780px",
          width: isNonMobile ? "900px" : "400px",
          marginTop: isNonMobile ? "0px" : "120px",
        }}
      >
        <h3 style={styles.header}>Register Here</h3>
        <Grid container spacing={2}>
          <Grid xs={12} sm={12} lg={6} item>
            <label htmlFor="businessName" style={styles.label}>
              Business/Studio Name
            </label>
            <input
              type="text"
              placeholder="Business/Studio Name"
              required
              name="businessName"
              value={values.businessName || ""}
              onChange={handleInputChange}
              style={styles.input}
            />
          </Grid>
          <Grid xs={12} sm={12} lg={6} item>
            <label htmlFor="ownerName" style={styles.label}>
              Your Name
            </label>
            <input
              type="text"
              placeholder="Enter Your Name"
              required
              name="ownerName"
              value={values.ownerName || ""}
              onChange={handleInputChange}
              style={styles.input}
            />
          </Grid>
          <Grid xs={12} sm={12} lg={6} item>
            <label htmlFor="phone" style={styles.label}>
              Phone
            </label>
            <input
              type="tel"
              placeholder="Phone"
              required
              name="phone"
              value={values.phone || ""}
              onChange={handleInputChange}
              style={styles.input}
            />
          </Grid>
          <Grid xs={12} sm={12} lg={6} item>
            <label htmlFor="email" style={styles.label}>
              Email
            </label>
            <input
              type="email"
              placeholder="Email"
              required
              name="email"
              value={values.email || ""}
              onChange={handleInputChange}
              style={styles.input}
            />
          </Grid>
          {/* <Grid xs={12} sm={12} lg={6} item>
            <label htmlFor="password" style={styles.label}>
              Password
            </label>
            <input
              type="password"
              placeholder="Password"
              required
              name="password"
              value={values.password || ""}
              onChange={handleInputChange}
              style={styles.input}
            />
          </Grid>
          <Grid xs={12} sm={12} lg={6} item>
            <label htmlFor="confirmPassword" style={styles.label}>
              Confirm Password
            </label>
            <input
              type="password"
              placeholder="Confirm Password"
              required
              name="confirmPassword"
              value={values.confirmPassword || ""}
              onChange={handleInputChange}
              style={styles.input}
            />
          </Grid> */}
        </Grid>
        {errorMessage && <p style={styles.errorMessage}>{errorMessage}</p>}

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <button
            type="submit"
            disabled={loading}
            style={{
              ...styles.button,
              width: "400px",
            }}
          >
            {loading ? "Submitting..." : "Register"}
          </button>
        </Box>
        <div
          style={{
            ...styles.nav,
            justifyContent: "end",
            marginRight: 0,
          }}
        >
          <div
            onClick={() => setSignup(false)}
            style={{ ...styles.navButton, marginRight: 0 }}
          >
            Back to Login
          </div>
        </div>
      </form>
    </div>
  );
};

export default RegisterAccount;
