import { Box, Typography, useTheme } from "@mui/material";
import React from "react";
import { tokens } from "../theme";

const TitleBar = ({
  children,
  borderBottom = true,
  isHeading,
  capitalize = true,
  endComponent,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      sx={{
        borderBottom: borderBottom && `thin solid ${colors.grey[500]}`,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{
          textTransform: `${capitalize && "uppercase"}`,

          paddingBottom: "3px",
        }}
        variant={isHeading ? "h2" : "h6"}
        color={colors.grey[100]}
      >
        {children}
      </Typography>
      <Box>{endComponent}</Box>
    </Box>
  );
};

export default TitleBar;
