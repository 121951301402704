import { Box, Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { getCurrentDate } from "../../../utils";



const CreateProject = ({ handleClose, handleSubmit }) => {
  const [values, setValues] = useState({
    selectionExpiryDate: getCurrentDate()
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  return (
    <form method="post" onSubmit={(e) => handleSubmit(e, values)}>
      <Box>
        <Box
          sx={{
            maxHeight: "60vh",
          }}
        >
          <TextField
            fullWidth
            variant="filled"
            type="text"
            label="Project Name"
            onChange={handleChange}
            value={values.projectName || ""}
            name="projectName"
            required
            sx={{
              gridColumn: "span 2",
              "& label.Mui-focused": { color: "inherit !important" },
            }}
          />
          <TextField
            fullWidth
            variant="filled"
            type="date"
            label="Selection Expiry Date"
            onChange={handleChange}
            value={values?.selectionExpiryDate}
            name="selectionExpiryDate"
            required
            sx={{
              gridColumn: "span 1",
              "& label.Mui-focused": { color: "inherit !important" },
            }}
            inputProps={{ min: getCurrentDate(), }} 
          />

          <TextField
            fullWidth
            multiline
            rows={2}
            variant="filled"
            label="Description"
            onChange={handleChange}
            value={values.description || ""}
            name="description"
            sx={{
              gridColumn: "span 4",
              "& label.Mui-focused": { color: "inherit !important" },
            }}
          />
        </Box>
        <Box
          sx={{
            mt: "20px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Button
            sx={{
              margin: "0px 10px",
            }}
            size="sm"
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button type="submit" size="xs" variant="contained" color="success">
            Create Project
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default CreateProject;
