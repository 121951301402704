import {
  Alert,
  Box,
  MenuItem,
  Select,
  Snackbar,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Table from "../../components/Table";
import TransitionsModal from "../../components/Modal";
import { useEffect, useState } from "react";
import api from "../../api/api";
import CreateProject from "./components/CreateProject";
import { useNavigate, useParams } from "react-router-dom";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import { getCurrentDate } from "../../utils";
import Top from "./components/Top";
import { AuthStore } from "../../context/authContext";

const Dashboard = ({ hasHeader = true }) => {
  const isNonMobile = useMediaQuery("(min-width:600px)");
  const { state } = AuthStore()
  const params = useParams()
  const userId = params?.userId || state?.profile?._id
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [message, setMessage] = useState({ type: "", message: "" });
  const [projectModal, setProjectModal] = useState(false);
  const [projectsUnderSelection, setProjectsUnderSelection] = useState(0);
  const navigate = useNavigate();

  const handleProjectModalClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setProjectModal(false);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      await api
        .get(`/projects/user/${userId}?page=${currentPage + 1}`)
        .then((res) => {
          setProjects(res.data.projects);
          setTotalRows(res.data.totalResults);
          setProjectsUnderSelection(res.data.projectsUnderSelection);
        })
        .catch((err) => {
          setMessage({
            type: "error",
            message: "Error loading page",
          });
          setSnackbarOpen(true);
        })
        .finally(() => {
          setLoading(false);
        });
    })();
  }, [currentPage, projectModal, userId]);

  const handleNavigate = (id) => {
    if (!hasHeader) return
    navigate(`/project/${id}`, { replace: true });
  };

  const handleUpdateProjectDetails = async (projectId, data) => {
    try {
      await api.put(`/project/${projectId}`, data);
      setProjects(
        projects.map((p) =>
          p._id === projectId ? { ...p, ...data } : p
        )
      );
      setMessage({
        type: "success",
        message: "Status changed successfully!!",
      });
      setSnackbarOpen(true);
    } catch (error) {
      setMessage({
        type: "error",
        message: "Error in changing status",
      });
      setSnackbarOpen(true);
    }
  }

  const columns = [
    {
      field: "projectName",
      headerName: "Project Name",
      flex: 1,
      cellClassName: "name-column--cell",
      minWidth: 200,
      renderCell: (params) => (
        <Box
          sx={{
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => handleNavigate(params.row._id)}
        >
          <PermMediaIcon fontSize="medium" />
          <Box
            sx={{
              marginLeft: "10px",
            }}
          >
            {params.row.projectName}
          </Box>
        </Box>
      ),
    },
    {
      field: "pin",
      headerName: "PIN",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "description",
      headerName: "Project Description",
      flex: 1,
      minWidth: 200,
    },

    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 200,
      renderCell: (params) => {
        const handleStatusChange = (event) => {
          const updatedStatus = event.target.value;
          handleUpdateProjectDetails(params.row._id, {
            status: updatedStatus
          })
        };

        return (
          <Select
            value={params.row.status}
            onChange={handleStatusChange}
            fullWidth
          >
            <MenuItem value="Ready For Uploads">Ready For Uploads</MenuItem>
            <MenuItem value="Ready For Selection">Ready For Selection</MenuItem>
            <MenuItem value="Completed">Completed</MenuItem>
          </Select>
        );
      }
    },

    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,

      renderCell: (params) => new Date(params.row.createdAt).toLocaleString(),
      minWidth: 200,
    },
    {
      field: "selectionExpiryDate",
      headerName: "Selection Expiry",
      flex: 1,
      renderCell: (params) => {
        const formattedDate = new Date(params.row.selectionExpiryDate).toISOString().split('T')[0];

        return (
          <TextField
            type="date"
            value={formattedDate}
            onChange={
              (e) => {
                handleUpdateProjectDetails(params.row._id, {
                  selectionExpiryDate: e.target.value
                })
              }
            }
            inputProps={{ min: getCurrentDate(), }}
          />
        );
      }
      ,
      minWidth: 200,
    },
  ];

  const createProject = async (e, values) => {
    e.preventDefault();
    setLoading(true);
    try {
      await api.post("/project", {
        ...values,
      });
      setMessage({
        type: "success",
        message: "Project Created Successfully!!",
      });
      setSnackbarOpen(true);
    } catch (error) {
      setMessage({
        type: "error",
        message:
          error?.response?.data?.message ||
          "Error occurred while creating page!!",
      });
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
      handleProjectModalClose();
    }
  };

  return (
    <Box m="20px">

      {
        hasHeader && <Top
          availableCredits={state?.profile?.credits}
          projectsUnderSelection={projectsUnderSelection}
          setProjectModal={setProjectModal}
          totalProjects={totalRows}
        />
      }

      <Box>
        <TransitionsModal
          width={isNonMobile ? 600 : "100%"}
          handleClose={handleProjectModalClose}
          open={projectModal}
        >
          <CreateProject
            handleSubmit={createProject}
            handleClose={handleProjectModalClose}
          />
        </TransitionsModal>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={7000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={message?.type || "info"}
          >
            {message?.message}
          </Alert>
        </Snackbar>

        <Table
          columns={columns}
          getRowId={(row) => row?._id}
          rows={projects}
          setCurrentPage={setCurrentPage}
          totalRows={totalRows}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default Dashboard;
