import { Autocomplete, Box, Grid, IconButton, TextField, useTheme } from '@mui/material'
import React from 'react'
import { tokens } from '../../theme';
import Header from '../../components/Header';
import VisibilityIcon from "@mui/icons-material/Visibility";

const UserDetail = ({ userDetails }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box
            m={"20px"}

        >
            <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
            }}>
                <Header
                    title="Profile Details"
                />
                <a
                    href={`/studio/${userDetails?.username}`}
                    target="_blank"
                    rel="noreferrer"
                >
                    <IconButton>
                        <VisibilityIcon />
                    </IconButton>
                </a>
            </Box>
            <Box
                p={"10px 20px"}
                mt={"20px"}
                sx={{
                    backgroundColor: colors.primary[400],
                    minHeight: '90vh'
                }}>
                <Grid container spacing={2} my={1} mb={3}>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Business/Studio Name"
                            disabled
                            value={userDetails.businessName || ""}
                            name="businessName"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Your Name"
                            disabled
                            value={userDetails.ownerName || ""}
                            name="ownerName"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={12} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            multiline
                            rows={3}
                            type="text"
                            label="About Business/Studio"
                            disabled
                            value={userDetails.aboutBusiness || ""}
                            name="aboutBusiness"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Email"
                            disabled
                            value={userDetails.email || ""}
                            name="email"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="tel"
                            label="Phone"
                            disabled
                            value={userDetails.phone || ""}
                            name="phone"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Instagram Id"
                            disabled
                            value={userDetails.instagram || ""}
                            name="instagram"
                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} lg={6} item>
                        <Autocomplete
                            multiple
                            options={[]}
                            freeSolo
                            filterSelectedOptions
                            value={userDetails.videoUrls || []}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    label="Video URLs"
                                />
                            )}
                            disabled
                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": { color: "inherit !important" },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>

                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Address"
                            disabled
                            value={userDetails.address || ""}
                            name="address"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="City"
                            disabled
                            value={userDetails.city || ""}
                            name="city"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="Country"
                            disabled
                            value={userDetails.country || ""}
                            name="country"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                    <Grid xs={12} sm={12} lg={6} item>
                        <TextField
                            fullWidth
                            variant="outlined"
                            type="text"
                            label="ZIP"
                            disabled
                            value={userDetails.zip || ""}
                            name="zip"

                            sx={{
                                gridColumn: "span 2",
                                "& label.Mui-focused": {
                                    color: "inherit !important",
                                },
                                "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#fff", // Changes outline color when focused
                                    },
                                },
                            }}
                        />
                    </Grid>
                </Grid>

            </Box>
        </Box>
    )
}

export default UserDetail